import { useEffect, useState, useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import useAbortEffect from "../components/hooks/useAbortEffect";
import Notifications from "../components/Notifications";
import asyncAPICall from "../util/apiWrapper";
import { ErrorMessage } from "../util/ErrorMessage";
import { me, ParDataContext } from "../defaultContainer";
import Modal from "../components/modals/Modal";
import { useAppData } from "../components/context/AppDataContext";
import DeletePARForm from "../components/forms/DeletePARForm";
import RejectCommentsForm from "../components/forms/RejectCommentsForm";

const OverviewPage = () => {
  const [parErrorMessage, setParErrorMessage] = useState(false);
  const [employeeTblData, setEmployeeTblData] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [safetyContact, setSafetyContact] = useState({
    name: "Retrieving...",
    phone: "###-###-####",
  });
  const { user } = useContext(me);
  const { parDispatch } = useContext(ParDataContext);
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState("");
  const [selectedPAR, setSelectedPAR] = useState(false);

  const { parReports, getParReports } = useAppData();

  const redirect = (parFormData) => {
    localStorage.setItem("formData", JSON.stringify(parFormData));
    parDispatch({
      type: "setParForm",
      form: parFormData,
    });
    history.push("/new-par");
  };

  const handleDeletePendingPAR = (par) => {
    setSelectedPAR(par);
    handleModalOpening("delete-par");
  };

  const handleModalOpening = useCallback((modalName) => {
    setModalDescription(modalName);
    return setIsModalOpen(true);
  }, []);

  useAbortEffect(
    (signal) => {
      if (user?.employeeID > 0) {
        asyncAPICall(
          `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${user.employeeID}`,
          "GET",
          null,
          null,
          (data) => {
            setEmployeeTblData(data);
          },
          (err) => {
            console.log("Get User Data Error:", err);
          },
          signal
        );
      }
    },
    [user]
  );

  useAbortEffect(
    (signal) => {
      if (employeeTblData.length > 0) {
        asyncAPICall(
          `api:KE7MCNYf/vprojectsafetywithid_byProject?project=${employeeTblData[0].lastJob}`,
          "GET",
          null,
          null,
          (data) => {
            if (data.length > 0) {
              setSafetyContact({
                name: data[0].safetyName,
                phone: data[0].workPhone,
              });
            } else {
              setSafetyContact({
                name: "No Contact",
                phone: "",
              });
            }
          },
          (err) => {
            console.log("Employee table data get error", err);
          },
          signal
        );
      }
    },
    [employeeTblData]
  );

  useEffect(() => {
    getParReports();
  }, [getParReports]);

  useEffect(() => {
    setAlerts([]);
  }, []);

  return (
    <div className="main-notifications-page">
      <div className="header-wrapper">
        <h1 className="header-h1">OVERVIEW</h1>

        <div className="external-links">
          <a
            className="instructions-link"
            href="https://scribehow.com/shared/PAR_Submitter__gurwseo3Rxu1Hu-diK0G9Q"
            target="_blank"
            rel="noreferrer"
          >
            PAR Instructions
          </a>

          <a
            className="instructions-link dashboard-link"
            href="https://bhico.domo.com/page/656594894"
            target="_blank"
            rel="noreferrer"
          >
            Production Dashboard
          </a>
        </div>
      </div>

      <div className="individual-notifications-containers">
        {alerts.length > 0 ? (
          <Notifications header="ALERTS" type="alerts" mockAlerts={alerts} />
        ) : (
          <></>
        )}

        <Notifications
          header="PENDING PAR'S"
          type="par"
          parReports={parReports}
          redirect={redirect}
          handleDeletePendingPAR={handleDeletePendingPAR}
          setIsCommentsModalOpen={handleModalOpening}
          setSelectedPAR={setSelectedPAR}
        />
        <Notifications
          header="APPROVED PAR'S"
          type="approvals"
          parReports={parReports}
        />
        <Notifications
          header="SAFETY CONTACT"
          type="safety"
          safetyContact={safetyContact}
        />
      </div>

      {parErrorMessage && (
        <ErrorMessage
          errorMessage="Sorry, we had a problem fetching your PAR reports"
          setIsErrorMessage={setParErrorMessage}
        />
      )}

      <Modal
        isModalOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        modalDescription={modalDescription}
        modalParts={{
          "delete-par": {
            modalContent: "verify-modal-styles",
            modalForm: (
              <DeletePARForm
                selectedPAR={selectedPAR}
                setIsModalOpen={setIsModalOpen}
                handleDeletePendingPAR={handleDeletePendingPAR}
              />
            ),
          },
          "reject-comments": {
            modalContent:
              window.screen.width <= 650
                ? { width: "350px", height: "400px" }
                : { width: "600px", height: "400px" },
            modalForm: (
              <RejectCommentsForm
                selectedPAR={selectedPAR}
                setIsCommentsModalOpen={setIsModalOpen}
              />
            ),
          },
        }}
      />
    </div>
  );
};

export default OverviewPage;
