export const todaysFormattedDate = () => {
  const date = new Date();
  const splitDate = date.toISOString().split("T")[0];
  const splitDayMonthYear = splitDate.split("-");
  const year = splitDayMonthYear[0].slice(2);
  const arrangedDate = `${splitDayMonthYear[1]}/${splitDayMonthYear[2]}/${year}`;

  return arrangedDate;
};

export const convertToInputDate = (date) => {
  const todaysDate = new Date();
  const year = String(todaysDate.getFullYear());
  const yearStart = year.slice(0, 2);
  const dateSplit = date.split("/");
  return `${yearStart}${dateSplit[2]}-${dateSplit[0]}-${dateSplit[1]}`;
};

export default function usableDate() {
  if (new Date().toLocaleString().split("/")[1].length > 1) {
    return (
      new Date().toISOString().slice(0, 8) +
      new Date().toLocaleString().split("/")[1]
    );
  } else {
    return (
      new Date().toISOString().slice(0, 8) +
      "0" +
      new Date().toLocaleString().split("/")[1]
    );
  }
}
