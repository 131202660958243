import { useState, useEffect, useContext } from "react";

import useDebounce from "../hooks/useDebounce";
import useAbortEffect from "../hooks/useAbortEffect";

import EmployeeItem from "./EmployeeItem";
import asyncAPICall from "../../util/apiWrapper";
import { ParDataContext } from "../../defaultContainer";
import Modal from "../modals/Modal";
import AllPhaseConfirmForm from "./AllPhaseConfirmForm";
import LoadingSpinner from "../helpers/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddEmployee = (props) => {
  const {
    phaseNum,
    setIsModalOpen,
    allPhaseEmployees,
    setAllPhaseEmployees,
    prevAllPhaseEmployees,
    setEmployeeInfo,
  } = props;

  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedData, setSelectedData] = useState([]);
  const { parFormDataState, parDispatch } = useContext(ParDataContext);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toRemove, setToRemove] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const debounceValue = useDebounce(searchValue);

  const submit = () => {
    if (setEmployeeInfo) {
      setIsModalOpen(false);
      return setEmployeeInfo(selectedData);
    }

    const removal = [];
    prevAllPhaseEmployees?.forEach((employee) => {
      if (!allPhaseEmployees.includes(employee)) {
        removal.push(employee.id);
      }
    });
    setToRemove(removal);

    if (setEmployeeInfo) {
      setEmployeeInfo();
    } else if (!phaseNum) {
      parDispatch({
        type: "addRecords",
        attributeName: "laborData",
        payload: allPhaseEmployees,
      });
    } else {
      setIsModalOpen(false);
      Object.keys(parFormDataState.phaseCodes).forEach((phaseCode) => {
        const checkedSelectedData = [];
        const employeeIDList = [];
        const payload = {
          phaseCode: phaseCode,
          attributeName: "laborData",
        };

        if (phaseCode === phaseNum || phaseCode === "012130-00-00") {
          parFormDataState.phaseCodes[phaseCode].laborData.forEach(
            (employee) => {
              employeeIDList.push(employee.id);
            }
          );

          selectedData.forEach((employeeData) => {
            const employeeDataRecord = {
              id: employeeData.id,
              name: employeeData.name,
              laborNotes: employeeData.laborNotes,
            };
            if (!employeeIDList.includes(employeeData.id)) {
              if (phaseCode === "012130-00-00") {
                employeeDataRecord.perDiem = employeeData.perDiem;
                employeeDataRecord.perDiemAmount = "";
                employeeDataRecord.combinedPhaseTime =
                  employeeData.combinedPhaseTime;
              } else if (parFormDataState.jobCertified === "Y") {
                employeeDataRecord.hoursWorked = employeeData.hoursWorked;
                employeeDataRecord.classes = {};
              } else {
                employeeDataRecord.hoursWorked = employeeData.hoursWorked;
              }

              checkedSelectedData.push(employeeDataRecord);
            }
          });

          payload.newRecords = checkedSelectedData;

          parDispatch({
            type: "addRecord",
            payload: payload,
          });
        }
      });
      setSelectedData([]);
    }
  };

  const renderData = () => {
    const idList = [];
    parFormDataState.phaseCodes[phaseNum]?.laborData.forEach((record) =>
      idList.push(record.id)
    );

    return filter.map((p) => {
      const selected = selectedData.some(
        (selectedRecord) => selectedRecord.id === p.employeeID
      );

      if (!idList.includes(p.employeeID)) {
        return (
          <EmployeeItem
            selectedEmployee={selected}
            data={p}
            key={p.employeeID}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            allPhaseEmployees={allPhaseEmployees}
            setAllPhaseEmployees={setAllPhaseEmployees}
            hoursWorked={props.hoursWorked}
            perDiem={props.perDiem}
            perDiemAmount={props.perDiemAmount}
            phaseNum={props.phaseNum}
            setEmployeeInfo={setEmployeeInfo}
          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    if (toRemove.length > 0) {
      parDispatch({
        type: "removeFromAllPhases",
        attributeName: "laborData",
        payload: toRemove,
      });
    }
  }, [toRemove, parDispatch]);

  useAbortEffect((signal) => {
    function getEmployees() {
      asyncAPICall(
        "api:KE7MCNYf/vemployeesnospaces",
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            setData(data.resource);
            setFilter(data.resource);
            setIsLoading(false);
          }
        },
        (err) => {
          if (!signal.aborted) {
            console.log("Getting employees Error", err);
          }
        },
        signal
      );
    }
    getEmployees();
  }, []);

  useEffect(() => {
    setFilter(
      data.filter(
        (employeeObject) =>
          employeeObject.LastName.toLowerCase().includes(
            debounceValue.toLowerCase()
          ) ||
          employeeObject.FirstName.toLowerCase().includes(
            debounceValue.toLowerCase()
          )
      )
    );
  }, [debounceValue, data]);

  return (
    <div className="main-modal-container">
      <div className="inside-container">
        <div className="header-container">
          <h1>
            {phaseNum ? `Phase Code ${phaseNum}` : "Adding to All Phases"}
          </h1>
        </div>

        <div className="header-container">
          <h1 className="select">Select Employee{!setEmployeeInfo && "s"}</h1>
          <FontAwesomeIcon className="employee" icon="fa fa-search" />

          <input
            type="search"
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </div>

        <hr />

        <div
          className={
            isLoading
              ? "main-content-container spinner"
              : "main-content-container employee-main-content"
          }
        >
          {isLoading ? <LoadingSpinner /> : renderData()}
        </div>

        <button
          onClick={
            !phaseNum && !setEmployeeInfo
              ? () => setConfirmModalOpen(true)
              : submit
          }
        >
          Save
        </button>
      </div>

      <Modal
        isModalOpen={confirmModalOpen}
        onRequestClose={() => setConfirmModalOpen(false)}
        modalDescription="all-phase-confirm"
        modalParts={{
          "all-phase-confirm": {
            modalContent: "verify-modal-styles",
            modalForm: (
              <AllPhaseConfirmForm
                setIsModalOpen={setConfirmModalOpen}
                submit={submit}
                parentSetShowModal={setIsModalOpen}
              />
            ),
          },
        }}
      />
    </div>
  );
};

export default AddEmployee;
