import Cookies from "js-cookie";

export const roles = {
  admin: ["JOB.ITSS", "JOB.DIT", "JOB.ITM"],
  pca: ["JOB.PCA"],
};

export default function SecurityWrapper({ roles, restrict_roles, children }) {
  const userRole = Cookies.get("par_permission");
  let childrenVisible = false;
  if (userRole) {
    if (roles && roles.includes(userRole)) {
      childrenVisible = true;
    } else if (restrict_roles) {
      childrenVisible = true;
      if (restrict_roles.roles.includes(userRole)) {
        childrenVisible = false;
      }
    }
  }
  return childrenVisible ? children : "";
}
