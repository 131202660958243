import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

import Modal from "../components/modals/Modal";

const mockData = {
  resource: [
    {
      id: 1,
      alertName: "Exhaustion Alert",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus facilis deleniti repellendus asperiores quae provident eius, voluptas ab adipisci ad officia eligendi atque, tempore est quidem consequatur tenetur. Qui, provident.",
      icon: "fas fa-exclamation-circle red",
      alertType: "alert-red",
      date: "06/06/2022",
      modified: "06/16/2022",
      uniqueID: "005B90C3-F643-43C5-BC42-9DE58D51DC51",
      foreman: 10933,
    },
    {
      id: 2,
      alertName: "Incident Report",
      description: "Onsite incidents that have been submitted",
      alertType: "alert-orange",
      icon: "fas fa-exclamation-circle orange",
    },
    {
      id: 3,
      alertName: "Status",
      description: "Onsite process that workers need info on.",
      alertType: "alert-green",
      icon: "fas fa-check-circle green",
    },
    {
      id: 4,
      alertName: "Delays",
      description: "Onsite delays in the construction process",
      alertType: "alert-blue",
      icon: "fas fa-circle-notch blue",
    },
  ],
  meta: {
    count: 4,
  },
};

const icons = [
  "fas fa-exclamation-circle red",
  "fas fa-exclamation-circle orange",
  "fas fa-check-circle green",
  "fas fa-circle-notch blue",
];

const Alerts = () => {
  const [showModal, setShowModal] = useState(false);
  const [alerts, setAlerts] = useState(mockData.resource);
  const [selectedId, setSelectedId] = useState("");
  const [selectedIcon, setSelectedIcon] = useState("");
  const [alertName, setAlertName] = useState("");
  const [alertDescription, setAlertDescription] = useState("");

  const addAlert = () => {
    const lastIndex = alerts.slice(-1);
    const lastId = lastIndex[0];
    const id = alerts.length > 0 ? lastId?.id + 1 : 1;
    setAlerts((a) => [
      ...a,
      {
        id: id,
        alertName: alertName,
        description: alertDescription,
        alertType: `alert-${selectedIcon.split(" ")[2]}`,
        icon: selectedIcon,
      },
    ]);
    setAlertName("");
    setAlertDescription("");
    setSelectedIcon("");
  };

  const renderAlertIcons = () => {
    return icons.map((icon, index) => {
      return (
        <div
          key={index}
          className={selectedIcon === icon ? "icon selected" : "icon"}
        >
          <FontAwesomeIcon
            icon={icon}
            className={icon}
            onClick={() => setSelectedIcon(icon)}
          />
        </div>
      );
    });
  };

  const handleSelectedAlert = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const renderAlertData = () => {
    return alerts.map((alert, index) => {
      return (
        <div className="alert-item-wrapper" key={index}>
          <div className={alert.alertType}>
            <div className="alert-name-wrapper">
              <div>
                <FontAwesomeIcon icon={alert.icon} className={alert.icon} />
              </div>

              <div className="alert-title">{alert.alertName}</div>

              <div>
                <FontAwesomeIcon
                  icon="fas fa-times"
                  onClick={() => handleSelectedAlert(alert.id)}
                />
              </div>
            </div>

            <div className="alert-description-wrapper">
              <div className="outside-box">
                <div className="inside-box">{alert.description}</div>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="alerts-container">
      <Modal
        isModal={showModal}
        setIsModal={setShowModal}
        setAlerts={setAlerts}
        id={selectedId}
      />

      <div className="header-wrapper">
        <h1>MANAGE ALERTS</h1>
      </div>

      <div className="alerts-wrapper">
        <div className="left-column">
          <div className="title">ALERTS</div>

          {renderAlertData()}
        </div>

        <div className="right-column">
          <div className="title">NEW ALERT</div>

          <div className="new-alerts">
            <div className="input-form-wrapper">
              <div className="input-name-wrapper">
                <input
                  type="text"
                  placeholder="Alert Name"
                  value={alertName}
                  onChange={(e) => setAlertName(e.target.value)}
                />
              </div>

              <div className="input-description-wrapper">
                <input
                  type="text"
                  placeholder="Alert Description"
                  value={alertDescription}
                  onChange={(e) => setAlertDescription(e.target.value)}
                />
              </div>
            </div>

            <div className="instruction">
              <p>Select Icon for New Alert</p>
            </div>

            <div className="color-icons">{renderAlertIcons()}</div>

            <div className="save-button-wrapper">
              <button
                className="save-button"
                disabled={!selectedIcon || !alertName || !alertDescription}
                onClick={addAlert}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alerts;
