import { useContext } from "react";
import { ParDataContext } from "../defaultContainer";

const NotesSection = (props) => {
  const {
    basicFieldUpdate,
    resetting,
    showJournalEntries,
    setShowJournalEntries,
  } = props;
  const { parFormDataState } = useContext(ParDataContext);

  return (
    <>
      <button
        className="section-label"
        onClick={() => setShowJournalEntries((prev) => !prev)}
      >
        Journal Entries
      </button>

      {showJournalEntries && (
        <div className="notes-wrapper">
          <label className="daily-journal-label">PFS Daily Journal</label>
          <textarea
            cols="30"
            rows="10"
            placeholder="Please record all important information pertaining to todays activities on your project. Who, What, Where, When, Why, and How. This information will be included in the Daily Construction Report and will be shared with others outside of BHI."
            value={parFormDataState.notes}
            onChange={(e) =>
              basicFieldUpdate(e.target.value, "notes", "updateInputField")
            }
            disabled={resetting}
          />
        </div>
      )}

      {showJournalEntries && (
        <div className="notes-wrapper">
          <label className="foreman-label">Foreman Daily Journal</label>
          <textarea
            className="foreman-daily-journal"
            cols="30"
            rows="10"
            placeholder="Please record all important information pertaining to your crew's activities today. Who, What, Where, When, Why, and How. This information will be recorded in the PAR database and made available to your leaders."
            value={parFormDataState.workPreformed}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "workPreformed",
                "updateInputField"
              )
            }
            disabled={resetting}
          />
        </div>
      )}

      {showJournalEntries && (
        <div className="notes-wrapper">
          <label>Foreman Forecasted Work</label>
          <textarea
            cols="30"
            rows="10"
            placeholder="Please record the work that you and your crew are planning to complete in the coming days and weeks. Who, What, Where, When, Why, and How. Include any resources, labor, materials equipment etc, that may be required to complete the forecasted work. Also include any constraints or conflicts that may keep the work from being completed. This information will be recorded in the PAR database and made available to your leaders."
            value={parFormDataState.workForecasted}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "workForecasted",
                "updateInputField"
              )
            }
            disabled={resetting}
          />
        </div>
      )}
    </>
  );
};

export default NotesSection;
