import { useState, useCallback } from "react";

import ParJobInformation from "../components/ParJobInformation";
import ParButtons from "../components/ParButtons";

const NewPar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDescription, setModalDescription] = useState("");
  const [bottomPhaseAdd, setBottomPhaseAdd] = useState(false);
  const [answeredModal, setAnsweredModal] = useState(null);

  const handleModalOpening = useCallback((modalName) => {
    setModalDescription(modalName);
    return setIsModalOpen(true);
  }, []);

  return (
    <div className="par-main-container">
      <ParJobInformation
        handleModalOpening={handleModalOpening}
        isModalOpen={isModalOpen}
        modalDescription={modalDescription}
        setIsModalOpen={setIsModalOpen}
        bottomPhaseAdd={bottomPhaseAdd}
        setBottomPhaseAdd={setBottomPhaseAdd}
        answeredModal={answeredModal}
        setAnsweredModal={setAnsweredModal}
      />

      <ParButtons
        handleModalOpening={handleModalOpening}
        setBottomPhaseAdd={setBottomPhaseAdd}
        answeredModal={answeredModal}
      />
    </div>
  );
};

export default NewPar;
