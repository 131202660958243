import React, { useState, useEffect, useReducer, createContext } from "react";
import { Route } from "react-router-dom";

import Header from "./header";
import Home from "./home";
import Overview from "./pages/Overview";
import NewPar from "./pages/NewPar";
import Alerts from "./pages/alerts";
import Approvals from "./pages/verification-approvals/Approvals";
import Cookies from "js-cookie";
import logout from "./util/logout";
import useDebounce from "./components/hooks/useDebounce";
import ApproveSubmissions from "./pages/ApproveSubmissions";
import SelectPrint from "./pages/SelectPrint";
import asyncAPICall from "./util/apiWrapper";
import { parReducer, baseParFormData } from "./components/reducer/actions";
import SecurityWrapper, { roles } from "./util/securityWrapper";
import { AppDataContextProvider } from "./components/context/AppDataContext";

export const ParDataContext = createContext(null);
export const LastSavedContext = createContext(null);
export const CheckboxValuesContext = createContext(null);
export const me = createContext(null);

const DefaultContainer = (props) => {
  const localStoragePar = JSON.parse(localStorage.getItem("formData"));
  const localStorageData = JSON.parse(localStorage.getItem("checkboxValues"))
    ? JSON.parse(localStorage.getItem("checkboxValues"))
    : {
        issues: true,
        injuries: true,
        subcontractor: true,
        incidents: true,
        material: true,
        timeMaterials: true,
        overnight: false,
      };
  const [searchTerm, setSearchTerm] = useState("");
  const [parCheckboxValues, setParCheckboxValues] = useState(localStorageData);
  const [parFormDataState, parDispatch] = useReducer(
    parReducer,
    localStoragePar ? localStoragePar : baseParFormData
  );
  const [lastSaved, setLastSaved] = useState("");
  const [user, setUser] = useState({});

  const debounceFormData = useDebounce(parFormDataState);

  useEffect(() => {
    let bearer_token_from_cookie = Cookies.get("bearer_token");
    let expiration = Cookies.get("auth_expires");
    let is_expired = Date.parse(expiration) < Date.now();
    if (!bearer_token_from_cookie || is_expired) {
      logout(props);
    }
  });

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("formData"));
    if (savedData) {
      parDispatch({
        type: "setParForm",
        form: savedData,
      });
    }
    setParCheckboxValues((d) => {
      const savedCheckbox = JSON.parse(localStorage.getItem("checkboxValues"));
      if (savedCheckbox) {
        return savedCheckbox;
      } else {
        return { ...d };
      }
    });
  }, []);

  useEffect(() => {
    asyncAPICall(
      "api:KE7MCNYf/auth/me",
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          Cookies.set("par_permission", data.parPermission);
          setUser(data);
        }
      },
      (err) => {
        console.log("error getting me", err);
      },
      null,
      true
    );
  }, []);

  useEffect(() => {
    localStorage.setItem("formData", JSON.stringify(debounceFormData));
    setLastSaved(new Date().toLocaleString());
  }, [debounceFormData]);

  useEffect(() => {
    localStorage.setItem("checkboxValues", JSON.stringify(parCheckboxValues));
  }, [parCheckboxValues]);

  return (
    <div className="container">
      <Route
        path="/"
        render={(iprops) => (
          <Header
            {...iprops}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            sessionToken={props.sessionToken}
            setSessionToken={props.setSessionToken}
          />
        )}
      />
      <div className="body-wrapper">
        <me.Provider value={{ user, setUser }}>
          <AppDataContextProvider>
            <ParDataContext.Provider value={{ parFormDataState, parDispatch }}>
              <Route path="/home" component={Home} />
              <Route path="/overview" component={Overview} />

              <LastSavedContext.Provider value={[lastSaved]}>
                <CheckboxValuesContext.Provider
                  value={[parCheckboxValues, setParCheckboxValues]}
                >
                  <Route path="/new-par" component={NewPar} />
                  <Route path="/par+:slug" component={NewPar} />
                </CheckboxValuesContext.Provider>
              </LastSavedContext.Provider>

              <Route exact path="/approvals" component={ApproveSubmissions} />
              <Route path="/approvals+:slug" component={ApproveSubmissions} />

              <SecurityWrapper roles={[...roles.admin, ...roles.pca]}>
                <Route path="/alerts" component={Alerts} />
              </SecurityWrapper>

              <Route path="/par-verification/:slug" component={Approvals} />

              <Route exact path="/select-print" component={SelectPrint} />
              <Route path="/select-print/:slug" component={SelectPrint} />
            </ParDataContext.Provider>
          </AppDataContextProvider>
        </me.Provider>
      </div>
    </div>
  );
};

export default DefaultContainer;
