import { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ParDataContext } from "../defaultContainer";

const Issues = (props) => {
  const {
    setSelectedIssue,
    handleModalOpening,
    description,
    issueType,
    dateRaised,
    issueNumber,
    setEditIssue,
  } = props;
  const { parDispatch } = useContext(ParDataContext);

  const handleEdit = () => {
    setEditIssue({
      issueNumber,
      description,
      issueType,
      dateRaised,
    });
    handleModalOpening("add-issue");
  };

  return (
    <tr className="table-row">
      <td className="issue-type">{issueType}</td>

      <td className="issue-date">{dateRaised}</td>

      <td className="issue-description">{description}</td>

      <td className="issue-edit">
        <button onClick={handleEdit}>Edit</button>{" "}
      </td>

      <td className="issue-delete">
        <FontAwesomeIcon
          icon="fas fa-trash"
          onClick={() => {
            parDispatch({
              type: "updateInputField",
              payload: {
                field: "issues",
                value: "",
              },
            });
            handleModalOpening("remove-issue");
            setSelectedIssue({
              issueNumber,
              description,
              issueType,
              dateRaised,
            });
          }}
        />
      </td>
    </tr>
  );
};

export default Issues;
