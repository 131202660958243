import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RejectCommentsForm = (props) => {
  const { selectedPAR, setIsCommentsModalOpen } = props;
  const renderComments = () => {
    return selectedPAR.statusComments.map((comment) => {
      return (
        <div className="reject-comments-wrapper" key={comment.id}>
          <hr />
          <div className="reject-comments-info">
            <div className="status-wrapper">
              <div className="status-date">{comment.date}</div>
              <div className="status">{comment.status}</div>
            </div>
            <div className="reject-comments">{comment.message}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="reject-comments-container">
      <FontAwesomeIcon
        icon="fas fa-times"
        onClick={() => setIsCommentsModalOpen(false)}
      />
      <h1>Comments on Rejected PAR</h1> {renderComments()}
    </div>
  );
};

export default RejectCommentsForm;
