import { useState, useEffect, useRef } from "react";

import LgYellowDesign from "../../img/lg-yellow-design.svg";
import { errorToast } from "../../util/toastNotifications";
import useAbortEffect from "../hooks/useAbortEffect";
import asyncAPICall from "../../util/apiWrapper";
import { ParInput, ParSelect, ParTextArea } from "../helpers/ParFormComponents";

const today = new Date().toISOString().split("T")[0];

const AddIssueForm = (props) => {
  const [issueType, setIssueType] = useState("");
  const [dateRaised, setDateRaised] = useState("");
  const [issueDescription, setIssueDescription] = useState("");
  const [availableTypes, setAvailableTypes] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(false);

  const {
    addRow,
    updateRow,
    issueNumber,
    setIsModalOpen,
    handleModalOpening,
    jobNumber,
    jobDescription,
    idb,
    foreman,
    editIssue,
    setShowIssues,
  } = props;

  const issuesLength = useRef(idb.length);
  const previousIssueNumber = useRef(issueNumber);

  const handleSubmit = (e) => {
    setShowIssues(true);
    e.preventDefault();
    setSaveDisabled(true);
    const updateValue = {
      dateRaised: dateRaised,
      description: issueDescription,
      issueNumber: issueNumber,
      issueType: issueType,
      raisedBy: foreman,
    };

    if (Object.keys(editIssue).length) {
      updateRow(updateValue, issueNumber);
      setIsModalOpen(false);
    } else if (issueType && dateRaised && issueDescription) {
      addRow();
    } else {
      setSaveDisabled(false);
      if (!issueType) errorToast("Missing Issue Type");
      if (!dateRaised) errorToast("Missing Issue Date");
      if (!issueDescription) errorToast("Missing Issue Description");
    }
  };

  useAbortEffect((signal) => {
    asyncAPICall(
      `api:W2oHkD04/issues_type`,
      "GET",
      null,
      null,
      (data) => {
        setAvailableTypes(data);
      },
      (err) => {
        if (!signal.aborted) console.error("Getting Issue Type Error", err);
      },
      signal
    );
  }, []);

  useEffect(() => {
    if (Object.keys(editIssue).length) {
      setIssueType(editIssue.issueType);
      setDateRaised(editIssue.dateRaised);
      setIssueDescription(editIssue.description);
    }
  }, [setIssueType, setDateRaised, setIssueDescription, editIssue]);

  useEffect(() => {
    const updateValue = {
      dateRaised: dateRaised,
      description: issueDescription,
      issueNumber: issueNumber,
      issueType: issueType,
      raisedBy: foreman,
    };

    if (
      idb.length === issuesLength.current + 1 &&
      issueNumber !== previousIssueNumber.current
    ) {
      updateRow(updateValue, issueNumber);

      setIsModalOpen(false);
      handleModalOpening("verify");
    }
  }, [
    idb,
    issueNumber,
    issueType,
    dateRaised,
    issueDescription,
    handleModalOpening,
    setIsModalOpen,
    updateRow,
    foreman,
  ]);

  return (
    <div className="add-issue-modal-container">
      <div className="header-img">
        <img
          className="header-graphic"
          src={LgYellowDesign}
          alt="header graphic"
        />
      </div>

      <div className="header-wrapper">
        <div className="add-header">
          <strong>{jobNumber}</strong>
          <div>{jobDescription}</div>
        </div>

        <button
          className="close-modal-button"
          onClick={() => setIsModalOpen(false)}
        >
          Close
        </button>
      </div>

      <div className="body-wrapper">
        <div className="type-date-wrapper">
          <div className="label-input-wrapper">
            <label>Issue Type</label>
            <ParSelect
              label=""
              labelClass="label-hidden"
              customClass="select-container"
              selectClass={` ${
                !issueType ? "type-input-invalid" : "type-input"
              }`}
              idKey="type"
              options={availableTypes}
              value={issueType}
              displayKeys={["type"]}
              onChange={(e) => setIssueType(e.target.value)}
            />
          </div>

          <div className="label-input-wrapper">
            <label>Issue Date</label>
            <ParInput
              inputClass={`date-input ${!dateRaised ? "invalid" : ""}`}
              type="date"
              value={dateRaised}
              label="Issue Date"
              labelClass="label-hidden"
              customClass="date-input-wrapper"
              max={today}
              onChange={(e) => setDateRaised(e.target.value)}
            />
          </div>
        </div>

        <div className="description-label-input-wrapper">
          <label className="description-label">Description</label>
          <ParTextArea
            customClass="description-input-wrapper"
            labelName="Description"
            labelClass="label-hidden"
            textareaClass="description-input"
            value={issueDescription}
            onChange={(e) => setIssueDescription(e.target.value)}
          />
        </div>

        <button
          className="save-modal-button"
          onClick={handleSubmit}
          disabled={saveDisabled}
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddIssueForm;
