import { useState, useContext, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ParDataContext } from "../../defaultContainer";
import LoadingSpinner from "../helpers/LoadingSpinner";
import Truncate from "../helpers/Truncate";

const AddClassForm = (props) => {
  const { classesSelected, setClassesSelected } = props;
  const [searchResult, setSearchResult] = useState("");
  const [hideButton, setHideButton] = useState(false);
  const { parDispatch } = useContext(ParDataContext);

  const submit = () => {
    setHideButton(true);
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: props.phaseNum,
        attributeName: "laborData",
        fieldName: "classes",
        updatedValue: classesSelected,
        rowId: props.employeeId,
        filterName: "id",
      },
    });
    setClassesSelected({});
    props.setIsModalOpen(false);
  };

  const handleClassClick = (jobClass) => {
    if (jobClass in classesSelected) {
      const filteredClasses = {};
      for (const key in classesSelected) {
        if (key !== jobClass) {
          filteredClasses[key] = "0";
        }
      }

      setClassesSelected({ ...filteredClasses });
    } else {
      setClassesSelected({
        ...classesSelected,
        [jobClass]: "0",
      });
    }
  };

  const filteredSearchClasses = useMemo(
    () =>
      props.pwClassesByJob.filter((searchedClass) =>
        searchedClass.Description.toLowerCase().includes(
          searchResult.toLowerCase()
        )
      ),
    [props.pwClassesByJob, searchResult]
  );

  const classesToShow = searchResult
    ? filteredSearchClasses
    : props.pwClassesByJob;

  return (
    <div className="main-job-container">
      <div className="job-wrapper">
        <div className="input-header-wrapper">
          <h2>Select Class</h2>

          <FontAwesomeIcon className="search-icon" icon="fa fa-search" />

          <input
            value={searchResult}
            type="search"
            onChange={(e) => setSearchResult(e.target.value)}
          />
        </div>

        <hr />

        <div
          className={
            props.isLoading ? "job-data-wrapper spinner" : "job-data-wrapper"
          }
        >
          {props.isLoading ? (
            <LoadingSpinner />
          ) : (
            classesToShow.map((jobClass) => {
              return (
                <div
                  key={jobClass.Class}
                  data-title={jobClass.Description}
                  onClick={() => handleClassClick(jobClass.Class)}
                  className={`job-item-wrapper ${
                    jobClass.Class in classesSelected ? "selected" : ""
                  }`}
                >
                  <Truncate content={jobClass.Description} maxChar={20} />
                </div>
              );
            })
          )}
        </div>

        <div className="save-btn-wrapper">
          <button
            onClick={submit}
            disabled={Object.keys(classesSelected).length === 0}
          >
            {hideButton ? "Loading" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};
export default AddClassForm;
