const RemoveMaterialForm = (props) => {
  const { deleteRow, selectedItem, setIsModalOpen } = props;

  return (
    <div className="remove-item-modal-container">
      <h3 className="remove-header">
        Are you sure you want to remove {selectedItem}?
      </h3>
      <button className="modal-button" onClick={deleteRow}>
        YES
      </button>
      <button className="modal-button" onClick={() => setIsModalOpen(false)}>
        NO
      </button>
    </div>
  );
};

export default RemoveMaterialForm;
