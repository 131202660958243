import { useContext, useState, useCallback } from "react";

import { ParDataContext } from "../defaultContainer";
import MaterialItem from "./MaterialItem";
import { calculateMaterialTotal } from "./helpers/calculateHours";

const Materials = (props) => {
  const {
    setSelectedMaterial,
    setCurrentMatId,
    handleModalOpening,
    basicFieldUpdate,
    uomArray,
    setBottomPhaseAdd,
    jobNumber,
    material,
  } = props;
  const { parFormDataState, parDispatch } = useContext(ParDataContext);
  const [showMaterials, setShowMaterials] = useState(true);

  const updateMaterial = useCallback(
    (e, id, updateField) => {
      parDispatch({
        type: "updateMaterial",
        updateField: updateField,
        value: e.target.value,
        id: id,
      });
    },
    [parDispatch]
  );

  const addMaterial = (materialType) => {
    if (
      parFormDataState.material?.length === 1 &&
      parFormDataState.material[0].id === 0
    ) {
      const firstMaterial = {
        id: 1,
        phaseCode: "",
        phaseDesc: "",
        description: "",
        units: "0.00",
        cost: "0.00",
        total: "0.00",
      };
      basicFieldUpdate([firstMaterial], "material", "updateInputField");
    } else {
      parDispatch({
        type: "addMaterial",
        materialType,
      });
    }
  };

  const renderMaterials = () => {
    return material
      .filter((row) => row.id !== 0)
      .map((row) => {
        return (
          <MaterialItem
            key={row.id}
            {...row}
            updateMaterial={updateMaterial}
            setSelectedMaterial={setSelectedMaterial}
            handleModalOpening={handleModalOpening}
            setCurrentMatId={setCurrentMatId}
            uomArray={uomArray}
            setBottomPhaseAdd={setBottomPhaseAdd}
            jobNumber={jobNumber}
          />
        );
      });
  };

  return (
    <div>
      <div className="heading-wrapper">
        <button
          className="section-label"
          onClick={() => setShowMaterials((prev) => !prev)}
        >
          Materials
        </button>
      </div>
      {showMaterials && (
        <div className="single-problem-container">
          <div className="table-container ">
            <table>
              <thead>
                <tr className="table-head">
                  <th className="padding-20"></th>
                  <th className="material-detail"></th>
                </tr>
              </thead>
              <tbody>{renderMaterials()}</tbody>
            </table>

            {material.length > 0 && (
              <div className="material-total-wrapper">
                <h3>
                  Total Material Cost:{" $"}
                  {calculateMaterialTotal(parFormDataState.material, "total")}
                </h3>
              </div>
            )}
          </div>
          <div className="material-container">
            <button className="bhi-material" onClick={() => addMaterial("bhi")}>
              Add Material
            </button>{" "}
          </div>
        </div>
      )}
    </div>
  );
};
export default Materials;
