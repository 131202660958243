import { useState } from "react";

const PhaseItem = (props) => {
  const {
    phaseObject,
    removePhase,
    addPhase,
    selectedPhase,
    setSelectedPhase,
    selected,
  } = props;

  const [phaseSelected, setPhaseSelected] = useState(selected);
  const isMatchingPhase = selectedPhase === phaseObject.Phase;
  const phaseKey = Object.keys(phaseObject);

  const handlePhaseObj = () => {
    if (phaseKey.includes("Project")) {
      phaseSelected ? removePhase() : addPhase();
    } else {
      isMatchingPhase ? removePhase() : addPhase();
    }
  };

  const handlePhaseClick = () => {
    if (phaseKey.includes("Project")) {
      setPhaseSelected((c) => !c);
    } else {
      setSelectedPhase(phaseObject.Phase);
    }

    handlePhaseObj();
  };

  return (
    <div
      className={`phase-item ${
        isMatchingPhase || phaseSelected ? "selected" : "not-selected"
      }`}
      onClick={handlePhaseClick}
      data-title={phaseObject.Description}
    >
      {phaseObject.Phase} ({phaseObject.Description.slice(0, 10)})
    </div>
  );
};

export default PhaseItem;
