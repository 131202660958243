import { useEffect } from "react";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const Modal = (props) => {
  const {
    isModalOpen,
    onRequestClose,
    content,
    overlay,
    children,
    modalDescription,
    modalParts,
  } = props;

  const verifyModalStyles = {
    width: "275px",
    height: "247px",
    backgroundColor: "#EAE9E9",
    textAlign: "center",
  };

  const handleModalContent = () => {
    if (modalParts && modalParts[modalDescription]?.modalContent) {
      if (modalParts[modalDescription].modalContent === "verify-modal-styles") {
        return verifyModalStyles;
      } else {
        return modalParts[modalDescription].modalContent;
      }
    } else {
      return content;
    }
  };

  const handleModalOverlay = () => {
    if (modalParts && modalParts[modalDescription]?.modalOverlay) {
      return modalParts[modalDescription].modalContent;
    } else {
      return overlay;
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      const navbar = document.getElementsByClassName("navbar-wrapper")[0];
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = "15px";
      navbar.style.maxWidth = "calc(100% - 15px)";
      return () => {
        document.body.style.overflow = "unset";
        document.body.style.paddingRight = "unset";
        navbar.style.maxWidth = "unset";
      };
    }
  }, [isModalOpen]);

  return (
    <ReactModal
      isOpen={isModalOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          backdropFilter: "blur(2px)",
          ...handleModalOverlay(),
        },
        content: {
          position: "absolute",
          top: "55%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "750px",
          height: "600px",
          borderRadius: "15px",
          padding: 0,
          overflow: "none",
          ...handleModalContent(),
        },
      }}
    >
      {modalParts && modalParts[modalDescription]?.modalForm
        ? modalParts[modalDescription].modalForm
        : children}
    </ReactModal>
  );
};

export default Modal;
