const RemoveEmployeeForm = (props) => {
  const { deleteRow, selectedItem, setIsModalOpen } = props;

  const handleYesClick = () => {
    deleteRow();
    setIsModalOpen(false);
  };

  return (
    <div className="remove-item-modal-container">
      <h3 className="remove-header">
        Are you sure you want to remove {selectedItem}?
      </h3>

      <button className="modal-button" onClick={handleYesClick}>
        YES
      </button>

      <button className="modal-button" onClick={() => setIsModalOpen(false)}>
        NO
      </button>
    </div>
  );
};

export default RemoveEmployeeForm;
