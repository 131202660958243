const IsOverNight = ({ handleIsOverNight }) => {
  return (
    <div className="remove-item-modal-container">
      <h3 className="remove-header">
        Does this include <span className="overnight-underline">Overnight</span>{" "}
        work?
      </h3>

      <button className="modal-button" onClick={() => handleIsOverNight(true)}>
        YES
      </button>

      <button className="modal-button" onClick={() => handleIsOverNight(false)}>
        NO
      </button>
    </div>
  );
};

export default IsOverNight;
