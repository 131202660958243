const EquipmentItem = (props) => {
  const { Equipment, Description } = props.equipmentData;

  const { isSelected, addRemoveEquipment } = props;

  return (
    <div
      className="equipment-item-wrapper"
      style={isSelected ? { color: "black" } : { color: "lightgray" }}
    >
      <div
        onClick={addRemoveEquipment}
        className="equipment"
        id={Equipment}
        selected={isSelected}
      >
        {Equipment}
      </div>

      <div className="description">{Description}</div>
    </div>
  );
};

export default EquipmentItem;
