import {
  useState,
  useCallback,
  useContext,
  createContext,
  useEffect,
} from "react";

import { me } from "../../defaultContainer";
import asyncAPICall from "../../util/apiWrapper";

const AppDataContext = createContext();

export const AppDataContextProvider = ({ children }) => {
  const [parReports, setParReports] = useState([]);
  const [submittedPARs, setSubmittedPARs] = useState([]);
  const { user } = useContext(me);

  const getParReports = useCallback(() => {
    if (user?.employeeID) {
      asyncAPICall(
        `api:W2oHkD04/partblmainBySubmitter?searchedSubmitter=${user.employeeID}`,
        "GET",
        null,
        null,
        (data) => {
          setParReports(data);
        },
        (err) => {
          console.error("error getting overview PAR reports", err);
        }
      );
    }
  }, [user.employeeID]);

  const getSubmittedReports = useCallback(() => {
    if (user?.employeeID)
      asyncAPICall(
        `api:W2oHkD04/partblmainByApprover?searchedApprover=${user.employeeID}`,
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            const submittedPARs = data.filter(
              (par) => par.dcrStatus === "Pending"
            );
            setSubmittedPARs(submittedPARs);
          }
        },
        (err) => {
          console.error("error getting approval PAR reports", err);
        }
      );
  }, [user.employeeID]);

  useEffect(() => {
    const parReports = getParReports();
    const submittedReports = getSubmittedReports();

    return {
      parReports,
      submittedReports,
    };
  }, [getParReports, getSubmittedReports]);

  const values = {
    parReports,
    getParReports,
    submittedPARs,
    getSubmittedReports,
    setSubmittedPARs,
  };

  return (
    <AppDataContext.Provider value={values}>{children}</AppDataContext.Provider>
  );
};

export const useAppData = () => {
  return useContext(AppDataContext);
};
