const EditExistingForm = (props) => {
  const { setIsModalOpen, resetPar, setResetting } = props;

  return (
    <div className="remove-item-modal-container">
      <h3 className="remove-header">
        Would you like to create a new PAR or edit the existing PAR?
      </h3>

      <button
        className="modal-button"
        onClick={() => {
          setResetting(true);
          resetPar("modal");
          setIsModalOpen(false);
        }}
      >
        NEW
      </button>

      <button className="modal-button" onClick={() => setIsModalOpen(false)}>
        EDIT
      </button>
    </div>
  );
};

export default EditExistingForm;
