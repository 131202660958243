import React from "react";
import { Route } from "react-router-dom";

import LoginPage from "./pages/loginPage";
import AuthPage from "./pages/AuthPage";

export default function loginContainer(props) {
  return (
    <div className="wrapper">
      <div className="container">
        <Route
          exact
          path="/login"
          render={(props) => (
            <LoginPage
              {...props}
              sessionToken={props.sessionToken}
              setSessionToken={props.setSessionToken}
            />
          )}
        />
        <Route path="/login/auth" component={AuthPage} />
      </div>
    </div>
  );
}
