import { useState, useEffect, useContext } from "react";

import useDebounce from "../hooks/useDebounce";
import PhaseItem from "../PhaseItem";
import asyncAPICall from "../../util/apiWrapper";
import { ParDataContext } from "../../defaultContainer";
import { getTotalHours } from "../helpers/totalHours";
import LoadingSpinner from "../helpers/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PhaseCodeForm = (props) => {
  const { setIsModalOpen, allPhaseEmployees, allPhaseEquipment } = props;
  const [data, setData] = useState([]);
  const [noPhaseCodesMsg, setNoPhaseCodesMsg] = useState(false);
  const [filteredData, setFilteredData] = useState(data);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const searchValue = useDebounce(searchInput);
  const { parFormDataState, parDispatch } = useContext(ParDataContext);
  const { jobNumber } = parFormDataState;
  const [selectedPhase, setSelectedPhase] = useState("");

  const renderData = () => {
    return filteredData.map((p) => {
      const phaseKeys = Object.keys(p);
      let selected = null;

      if (phaseKeys.includes("Project")) {
        selected = Object.keys(parFormDataState.phaseCodes).some(
          (phase) => phase === p.Phase
        );
      }

      return (
        <PhaseItem
          phaseObject={p}
          key={p.id}
          selected={selected}
          addPhase={() => addPhase(p.Phase, p.Description)}
          removePhase={() => removePhase(p.Phase)}
          selectedPhase={selectedPhase}
          setSelectedPhase={setSelectedPhase}
        />
      );
    });
  };

  const addPhase = (phase, description) => {
    const employeeIDList = [];
    const employeeList = [];
    const equipNums = [];
    const equipList = [];
    const payload = {
      phaseCode: phase,
      attributeName: "laborData",
    };
    const equipPayload = {
      phaseCode: phase,
      attributeName: "equipmentData",
    };

    if (props.currentMatId || props.currentMatId === 0) {
      parDispatch({
        type: "updateMaterial",
        updateField: "phaseCode",
        value: phase,
        id: props.currentMatId,
      });

      parDispatch({
        type: "updateMaterial",
        updateField: "phaseDesc",
        value: description,
        id: props.currentMatId,
      });
      return;
    }

    if (phase !== "012130-00-00") {
      allPhaseEmployees.forEach((employee) => {
        employeeList.push(employee);
      });
      allPhaseEquipment.forEach((equipment) => {
        equipList.push(equipment);
      });
    }

    if (phase === "012130-00-00") {
      Object.keys(parFormDataState.phaseCodes).forEach((phaseCode) => {
        parFormDataState.phaseCodes[phaseCode].laborData.forEach((employee) => {
          if (!employeeIDList.includes(employee.id)) {
            const employeeCopy = {
              name: "",
              id: "",
              laborNotes: "",
            };

            employeeCopy.name = employee.name;
            employeeCopy.id = employee.id;
            employeeCopy.laborNotes = employee.laborNotes;

            if (phase === "012130-00-00") {
              employeeCopy.combinedPhaseTime = getTotalHours(
                employee.id,
                parFormDataState,
                "labor"
              );
              employeeCopy.perDiemAmount = "";
              employeeCopy.perDiem = false;
            } else {
              employeeCopy.hoursWorked = "0";
            }

            employeeIDList.push(employee.id);
            employeeList.push(employeeCopy);
          }
        });

        parFormDataState.phaseCodes[phaseCode].equipmentData.forEach(
          (equipment) => {
            if (!equipNums.includes(equipment.equipNum)) {
              const equipCopy = {
                description: equipment.description,
                equipNote: equipment.equipNote,
                equipNum: equipment.equipNum,
                operatedHours: equipment.operatedHours,
              };

              equipNums.push(equipment.equipNum);
              equipList.push(equipCopy);
            }
          }
        );
      });
    }

    parDispatch({
      type: "addPhaseCode",
      payload: {
        phase: phase,
        description: description,
      },
    });
    if (employeeList.length > 0) {
      payload.newRecords = employeeList;
      parDispatch({
        type: "addRecord",
        payload: payload,
      });
    }
    if (equipList.length > 0) {
      equipPayload.newRecords = equipList;
      parDispatch({
        type: "addRecord",
        payload: equipPayload,
      });
    }
    if (props.bottomPhaseAdd)
      props.lastSelected.current = `${phase} (${description})`.replace(
        " ",
        "+"
      );
  };

  const removePhase = (phase) => {
    parDispatch({
      type: "removePhaseCode",
      payload: {
        selectedPhase: phase,
      },
    });

    props.lastSelected.current = null;
  };

  useEffect(() => {
    const checkForMatch = (phaseObject) => {
      const lowerCaseDescription = phaseObject.Description.toLowerCase().trim();
      return (
        phaseObject.Phase.trim().includes(searchValue) ||
        lowerCaseDescription.includes(searchValue.toLowerCase())
      );
    };
    setFilteredData(data.filter(checkForMatch));
  }, [searchValue, data]);

  useEffect(() => {
    const getPhaseCodes = () => {
      asyncAPICall(
        props.currentMatId || props.currentMatId === 0
          ? `api:W2oHkD04/vmaterialphases?jobNumber=${jobNumber}`
          : `api:KE7MCNYf/vprojectphasesbyproject?jobNumber=${jobNumber}`,
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            setData(data);
            setFilteredData(data);
            setIsLoading(false);
            if (data.length === 0) {
              setNoPhaseCodesMsg(true);
            } else {
              setNoPhaseCodesMsg(false);
            }
          }
        },
        (err) => {
          console.log("Getting Phases Error", err);
        }
      );
    };
    getPhaseCodes();
  }, [jobNumber, props.currentMatId]);

  return (
    <div className="main-modal-container">
      <div className="inside-container">
        <div className="header-container">
          <h1>Select Phase Codes</h1>

          <FontAwesomeIcon className="phase-search-icon" icon="fa fa-search" />

          <input
            type="search"
            onChange={(e) => setSearchInput(e.target.value)}
          />
        </div>

        <hr />

        <div
          className={
            isLoading
              ? "main-content-container spinner"
              : "main-content-container"
          }
        >
          {isLoading ? <LoadingSpinner /> : renderData()}
          {noPhaseCodesMsg ? (
            <div className="no-phase-codes-msg-wrapper">
              <div className="no-phase-codes-msg">No Phase Codes</div>
            </div>
          ) : null}
        </div>

        <button onClick={() => setIsModalOpen(false)}>Save</button>
      </div>
    </div>
  );
};

export default PhaseCodeForm;
