import { useState, useEffect, useCallback, useContext, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useAbortEffect from "./hooks/useAbortEffect";
import PhaseCodeItem from "./phaseCodeItem";
import asyncAPICall from "../util/apiWrapper";
import {
  ParDataContext,
  LastSavedContext,
  CheckboxValuesContext,
} from "../defaultContainer";
import {
  ParInput,
  ParSelect,
  ParTextArea,
  ParCheckbox,
  ParTable,
} from "./helpers/ParFormComponents";
import { baseParFormData } from "./reducer/actions";
import { checkSameDayPar } from "./helpers/checkSameDayPar";
import { errorToast } from "../util/toastNotifications";
import { convertToInputDate } from "../util/dayString";

import RemoveEmployeeForm from "./forms/RemoveEmployeeForm";
import Modal from "./modals/Modal";
import ResetParForm from "./forms/ResetParForm";
import EditExistingForm from "./forms/EditExistingForm";
import PhaseCodeForm from "./forms/PhaseCodeForm";
import Autofill from "./forms/autofill";
import EquipmentForm from "./forms/EquipmentForm";
import JobForm from "./forms/JobForm";
import AddEmployee from "./forms/AddEmployee";
import RemoveMaterialForm from "./forms/RemoveMaterialForm";
import Issues from "./Issues";
import RemoveIssueForm from "./forms/RemoveIssueForm";
import AddIssueForm from "./forms/AddIssueForm";
import ApproverChanges from "./ApproverChanges";
import VerifyForm from "./forms/VerifyForms";
import TimeMaterials from "./TimeMaterials";
import IsOverNight from "./forms/IsOverNightForm";
import Subcontractors from "./Subcontractors";
import Materials from "./Materials";
import NotesSection from "./NotesSection";

const ParJobInformation = (props) => {
  const {
    modalDescription,
    handleModalOpening,
    isModalOpen,
    setIsModalOpen,
    bottomPhaseAdd,
    setBottomPhaseAdd,
    answeredModal,
    setAnsweredModal,
  } = props;

  const location = useLocation();
  const history = useHistory();
  const todaysDate = new Date();
  const todaysDateFormatted = todaysDate.toISOString().split("T")[0];
  const stopTimeRef = useRef(null);
  const phaseCodesRef = useRef([]);
  const lastSelected = useRef();
  const [checkboxValues, setCheckboxValues] = useContext(CheckboxValuesContext);
  const { parFormDataState, parDispatch } = useContext(ParDataContext);
  const lastSaved = useContext(LastSavedContext);

  const [headerToggle, setHeaderToggle] = useState(false);
  const [pfsList, setPfsList] = useState([]);
  const [jobInfoComplete, setJobInfoComplete] = useState(false);
  const [parHours, setParHours] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [editIssue, setEditIssue] = useState({});
  const [selectedSubcontractor, setSelectedSubcontractor] = useState("");
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [autofillData, setAutofillData] = useState([]);
  const [perDiem, setPerDiem] = useState(false);
  const [perDiemAmount, setPerDiemAmount] = useState(0);
  const [submitterName, setSubmitterName] = useState("");
  const [approvedSubcontractors, setApprovedSubcontractors] = useState([]);
  const [parSubmitterId, setParSubmitterId] = useState(0);
  const [allPhaseEmployees, setAllPhaseEmployees] = useState([]);
  const [allPhaseEquipment, setAllPhaseEquipment] = useState([]);
  const [currentMatId, setCurrentMatId] = useState("");
  const [uomArray, setUomArray] = useState([]);
  const [resetting, setResetting] = useState(false);
  const [jobLocation, setJobLocation] = useState(null);
  const [checkboxType, setCheckboxType] = useState("");
  const [formattedDate, setFormattedDate] = useState(
    convertToInputDate(parFormDataState?.date)
  );
  const [showIssues, setShowIssues] = useState(false);
  const [startHours, setStartHours] = useState(0);
  const [endHours, setEndHours] = useState(0);
  const [timeChange, setTimeChange] = useState(false);
  const [showJournalEntries, setShowJournalEntries] = useState(true);

  const {
    pfs,
    jobNumber,
    jobDescription,
    dcrNumber,
    foreman,
    wind,
    precipitation,
    temperatureHigh,
    temperatureLow,
    date,
    safetyTopic,
    startTime,
    stopTime,
    forecast,
    issues,
    injuries,
    incidents,
    idb,
    subcontractor,
    material,
    phaseCodes,
  } = parFormDataState;

  const localStorageEmployees = JSON.parse(
    localStorage.getItem("allPhaseEmployees")
  )
    ? JSON.parse(localStorage.getItem("allPhaseEmployees"))
    : [];
  const [prevAllPhaseEmployees, setPrevAllPhaseEmployees] = useState(
    localStorageEmployees
  );
  const localStorageEquipment = JSON.parse(
    localStorage.getItem("allPhaseEquipment")
  )
    ? JSON.parse(localStorage.getItem("allPhaseEquipment"))
    : [];
  const [prevAllPhaseEquipment, setPrevAllPhaseEquipment] = useState(
    localStorageEquipment
  );

  const removeFocus = () => {
    stopTimeRef.current?.blur();
  };

  const resetPar = (type) => {
    const resetForm = { ...baseParFormData };
    resetForm.foreman = parSubmitterId;
    setAutofillData([]);
    setJobLocation(null);
    setAnsweredModal(false);
    setShowJournalEntries(true);

    if (type === "modal") {
      setFormattedDate(todaysDateFormatted);
      setCheckboxValues({
        issues: true,
        injuries: true,
        subcontractor: true,
        incidents: true,
        material: true,
        timeMaterials: true,
        overnight: false,
      });
      setAllPhaseEmployees([]);
      setAllPhaseEquipment([]);
    } else {
      resetForm.date = parFormDataState.date;
    }
    const resetDispatch = new Promise((res, rej) => {
      try {
        parDispatch({
          type: "setParForm",
          form: resetForm,
        });

        res(true);
      } catch (error) {
        rej(error.message);
      }
    });

    resetDispatch
      .then(() => setResetting(false))
      .catch((err) => console.error("Resetting PAR error: ", err));

    if (modalDescription === "reset-par") setIsModalOpen(false);
  };

  const basicFieldUpdate = useCallback(
    (value, field, type) => {
      parDispatch({
        type,
        payload: {
          field,
          value,
        },
      });
    },
    [parDispatch]
  );

  const updateIdb = useCallback(
    (value, issueNumber) => {
      parDispatch({
        type: "updateIdb",
        value: value,
        issueNumber: issueNumber,
      });
    },
    [parDispatch]
  );

  const addIdb = () => {
    parDispatch({
      type: "addIdb",
    });
  };

  const deleteIdb = () => {
    parDispatch({
      type: "deleteIdb",
      payload: {
        idb: selectedIssue.issueNumber,
      },
    });
    if (modalDescription === "remove-issue") {
      setIsModalOpen(false);
    }
  };

  const deleteSubcontractor = () => {
    parDispatch({
      type: "deleteSubcontractor",
      payload: {
        subcontractor: selectedSubcontractor.id,
      },
    });
    if (modalDescription === "remove-employee") setIsModalOpen(false);
  };

  const deleteMaterial = () => {
    parDispatch({
      type: "deleteMaterial",
      payload: {
        material: selectedMaterial.id,
      },
    });
    if (modalDescription === "remove-material") setIsModalOpen(false);
  };

  const removePhase = (phaseNum) => {
    parDispatch({
      type: "removePhaseCode",
      selectedPhase: phaseNum,
    });
  };

  const handleIdbChange = () => {
    if (checkboxValues.issues) {
      setCheckboxType("issues");
      handleModalOpening("verify-no-issues");
    } else {
      setCheckboxValues((c) => ({ ...c, issues: true }));
      if (
        parFormDataState.idb.length > 0 &&
        parFormDataState.idb[0]?.issueNumber === 0
      ) {
        basicFieldUpdate([], "idb", "updateInputField");
      }
    }
  };

  const handleVerifyNoClick = () => {
    setCheckboxValues((c) => ({ ...c, [checkboxType]: true }));

    return modalDescription === "verify-no-issues"
      ? setIsModalOpen(false)
      : null;
  };

  const handleVerifyYesClick = () => {
    if (checkboxType === "issues") {
      const emptyIssue = {
        issueNumber: 0,
        issueType: "No Issues Entered",
        description: "",
        dateRaised: "",
        raisedBy: "",
      };
      setCheckboxValues((c) => {
        basicFieldUpdate([emptyIssue], "idb", "updateInputField");
        return { ...c, [checkboxType]: false };
      });
    }

    return modalDescription === "verify-no-issues"
      ? setIsModalOpen(false)
      : null;
  };

  const handleAnotherIssueYes = () => {
    if (modalDescription === "verify") setIsModalOpen(false);
    handleModalOpening("add-issue");
  };

  const handleOvernightCheck = () => {
    setCheckboxValues((prev) => ({ ...prev, overnight: !prev.overnight }));
    if (stopTime < startTime) {
      if (checkboxValues.overnight === true) {
        setParHours(`Enter Valid Stop Time`);
      } else {
        setAnsweredModal(true);
      }
    } else {
      setAnsweredModal(true);
    }
  };

  const handleIsOverNight = (overnight) => {
    setIsModalOpen(false);
    setAnsweredModal(true);
    setCheckboxValues((c) => ({ ...c, overnight: overnight }));
  };

  const getWeather = useCallback(async () => {
    setIsLoading(true);
    const accuWeatherKey = process.env.REACT_APP_ACCUWEATHER_API_KEY;
    try {
      if (jobNumber !== "") {
        if (jobLocation !== null) {
          const locationKey = await fetch(
            `https://dataservice.accuweather.com/locations/v1/cities/geoposition/search?apikey=${accuWeatherKey}&q=${jobLocation}`
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                errorToast(
                  "Location Error: Unable to Get Location Data From AccuWeather."
                );
                return null;
              }
            })
            .then((data) => {
              return data.Key;
            });

          await fetch(
            `https://dataservice.accuweather.com/forecasts/v1/daily/1day/${locationKey}?apikey=${accuWeatherKey}&details=true`
          )
            .then((res) => {
              if (res.ok) {
                return res.json();
              } else {
                errorToast("AccuWeather Service Error: No Forecast Available");
                return null;
              }
            })
            .then((data) => {
              if (Object.keys(data).length > 0) {
                const dailyForeCast = data.DailyForecasts[0];
                const payload = {
                  temperatureHigh:
                    dailyForeCast.Temperature.Maximum.Value?.toString(),
                  temperatureLow:
                    dailyForeCast.Temperature.Minimum.Value?.toString(),
                  wind: `${dailyForeCast.Day.Wind.Speed.Value} MPH Gusts up to ${dailyForeCast.Day.WindGust.Speed.Value} MPH`,
                  forecast: dailyForeCast.Day.LongPhrase,
                  precipitation: dailyForeCast.Day.PrecipitationType
                    ? dailyForeCast.Day.PrecipitationType
                    : "None",
                };
                parDispatch({
                  type: "setWeatherInputs",
                  payload: payload,
                });
              }
              setIsLoading(false);
              return data;
            });
        } else {
          setIsLoading(false);
          errorToast("Location Error: Job Location Missing From Viewpoint.");
        }
      } else {
        setIsLoading(false);
        errorToast("Please select a job number");
      }
    } catch (error) {
      console.error("Get Weather Error: ", error);
      setIsLoading(false);
    }
  }, [jobLocation, parDispatch, jobNumber]);

  useAbortEffect(
    (signal) => {
      if (jobNumber && parSubmitterId) {
        checkSameDayPar(jobNumber, date, parSubmitterId, signal).then((res) => {
          if (res && !dcrNumber) {
            errorToast(
              `A PAR has already been created for ${jobNumber} with your user credentials for ${formattedDate}.`
            );
            basicFieldUpdate("", "jobNumber", "updateInputField");
            if (modalDescription !== "edit-existing-par") setIsModalOpen(false);
            setResetting(false);
          } else {
            const latLonLocation = new Promise((res, rej) => {
              asyncAPICall(
                `api:W2oHkD04/vlatlongByJob?jobSearch=${jobNumber}`,
                "GET",
                null,
                null,
                (data) => {
                  setJobLocation(data[0]?.latLong);
                  res(true);
                },
                (err) => {
                  if (signal.aborted) {
                    res(true);
                  } else {
                    rej(err);
                    console.error(
                      `No Location Information Set for Job Number ${jobNumber}.`
                    );
                    errorToast(
                      `No Location Information Set for Job Number ${jobNumber}.`
                    );
                  }
                },
                signal
              );
            });

            const perDiemPromise = new Promise((res, rej) => {
              asyncAPICall(
                `api:KE7MCNYf/vprojectswithid_by_Project?project=${jobNumber}`,
                "GET",
                null,
                null,
                (data) => {
                  if (data.length > 0) {
                    if (data[0].perDiem === "Y") {
                      setPerDiem(true);
                      setPerDiemAmount(data[0].perDiemAmount);
                    }
                  }
                  res(true);
                },
                (err) => {
                  if (signal.aborted) {
                    res(true);
                  } else {
                    rej(err);
                    console.error("Setting Per Diem Error", err);
                  }
                },
                signal
              );
            });

            const multipleApproverPromise = new Promise((res, rej) => {
              asyncAPICall(
                `api:W2oHkD04/vmultipleapproversbyjob?job=${jobNumber}`,
                "GET",
                null,
                null,
                (data) => {
                  if (data.length > 0) {
                    setPfsList(data);
                    res(true);
                  } else {
                    setPfsList([]);
                    errorToast("No Approvers Assinged To Job");
                    res(true);
                  }
                },
                (err) => {
                  if (signal.aborted) {
                    res(true);
                  } else {
                    rej(err);
                    console.error("Get PM Error", err);
                  }
                },
                signal
              );
            });

            const autofillPromise = new Promise((res, rej) => {
              asyncAPICall(
                `api:W2oHkD04/dcrtblautofill?jobNumber=${jobNumber}`,
                "GET",
                null,
                null,
                (data) => {
                  if (data) {
                    setAutofillData(data);
                    res(true);
                  }
                },
                (err) => {
                  if (signal.aborted) {
                    res(true);
                  } else {
                    rej(err);
                    console.error("Getting Autofill Data Error", err);
                  }
                },
                signal
              );
            });

            const subcontractorsPromise = new Promise((res, rej) => {
              asyncAPICall(
                `api:E3-qrfOe/vbhisubcontractorsByVendor?jobSearch=${jobNumber}`,
                "GET",
                null,
                null,
                (data) => {
                  if (data) {
                    setApprovedSubcontractors(data);
                    res(true);
                  }
                },
                (err) => {
                  if (signal.aborted) {
                    res(true);
                  } else {
                    rej(err);
                  }
                },
                signal
              );
            });

            const uomPromise = new Promise((res, rej) => {
              asyncAPICall(
                `api:E3-qrfOe/vdistinctuom`,
                "GET",
                null,
                null,
                (data) => {
                  if (data) {
                    setUomArray(data);
                    res(true);
                  }
                },
                (err) => {
                  if (signal.aborted) {
                    res(true);
                  } else {
                    rej(
                      console.error(
                        "error getting units of measurement (uom)",
                        err
                      )
                    );
                  }
                },
                signal
              );
            });

            Promise.all([
              perDiemPromise,
              latLonLocation,
              multipleApproverPromise,
              autofillPromise,
              subcontractorsPromise,
              uomPromise,
            ])
              .then(
                () => {
                  if (!signal.aborted) {
                    if (modalDescription === "edit-existing-par") {
                      setResetting(false);
                    } else {
                      setIsModalOpen(false);
                      setResetting(false);
                    }
                  }
                },
                (err) => console.log("Failed to get subcontractor", err)
              )
              .catch((err) => console.error(err));
          }
        });
      }
    },
    [
      jobNumber,
      basicFieldUpdate,
      parDispatch,
      date,
      formattedDate,
      parSubmitterId,
      dcrNumber,
    ]
  );

  useAbortEffect(
    (signal) => {
      if (!parSubmitterId) {
        asyncAPICall(
          `api:KE7MCNYf/auth/me`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              setSubmitterName(data.name);
              const newForeman = data === undefined ? "--" : data.name;
              basicFieldUpdate(newForeman, "foreman", "updateInputField");
              setParSubmitterId(data.employeeID);
              parDispatch({
                type: "updateInputField",
                payload: {
                  field: "foreman",
                  value: data.length === 0 ? "--" : data.employeeID,
                },
              });
            }
          },
          (err) => {
            console.error("Getting Foreman Error", err);
          },
          signal
        );
      }
    },
    [parSubmitterId, parDispatch, basicFieldUpdate]
  );

  useEffect(() => {
    const currentLocation =
      location.pathname === "/new-par" || `/par+${dcrNumber}`;

    const unblock = history.block((location, action) => {
      const destination =
        location.pathname === "/par-verification/par" ||
        `/par-verification/${dcrNumber}`;

      if (currentLocation && destination && action === "POP") {
        errorToast("Click 'Verify' to Continue");

        return false;
      }

      return true;
    });

    return () => {
      unblock();
    };
  }, [location, history, dcrNumber]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("allPhaseEmployees"));
    if (savedData) {
      setAllPhaseEmployees(
        JSON.parse(localStorage.getItem("allPhaseEmployees"))
      );
    }

    const savedCheckboxValues = JSON.parse(
      localStorage.getItem("checkboxValues")
    );

    if (savedCheckboxValues) {
      setCheckboxValues(savedCheckboxValues);
    }
  }, [setCheckboxValues]);

  useEffect(() => {
    if (!idb) {
      if (issues !== "Nothing to Report." && issues.length > 0) {
        parDispatch({
          type: "addDeprecatedIdb",
          payload: issues,
        });
      }
    } else if (
      idb.length === 0 &&
      issues !== "Nothing to Report." &&
      issues.length > 0
    ) {
      parDispatch({
        type: "addDeprecatedIdb",
        payload: issues,
      });
    }
  });

  useEffect(() => {
    localStorage.setItem(
      "allPhaseEmployees",
      JSON.stringify(allPhaseEmployees)
    );
  }, [allPhaseEmployees]);

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("allPhaseEquipment"));
    if (savedData) {
      setAllPhaseEquipment(
        JSON.parse(localStorage.getItem("allPhaseEquipment"))
      );
    }
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "allPhaseEquipment",
      JSON.stringify(allPhaseEquipment)
    );
  }, [allPhaseEquipment]);

  useEffect(() => {
    if (
      pfs !== 0 &&
      foreman.length !== "" &&
      jobNumber.length > 0 &&
      date.length > 0 &&
      forecast.length > 0 &&
      precipitation.length > 0 &&
      temperatureHigh.length > 0 &&
      temperatureLow.length > 0 &&
      wind.length > 0 &&
      safetyTopic.length > 0 &&
      startTime.length > 0 &&
      stopTime.length > 0
    ) {
      setJobInfoComplete(true);
    } else {
      setJobInfoComplete(false);
    }
  }, [
    pfs,
    foreman,
    jobNumber,
    date,
    forecast,
    precipitation,
    temperatureHigh,
    temperatureLow,
    wind,
    safetyTopic,
    startTime,
    stopTime,
  ]);

  useEffect(() => {
    setStartHours(startTime.slice(0, 2));
    setEndHours(stopTime.slice(0, 2));
    const startMinutes = startTime.slice(3, 5);
    const endMinutes = stopTime.slice(3, 5);
    let minutes = String(endMinutes - startMinutes);
    let hours = String(endHours - startHours);

    const intMin = Number(minutes);

    if (intMin < 0) {
      hours = String(Number(hours) - 1);
      minutes = String(Number(minutes) + 60);
    }

    if (minutes.length < 2) {
      minutes = `0${minutes}`;
    }

    if (Number(hours) <= 0 && startHours !== "" && endHours !== "") {
      if (
        startTime > stopTime &&
        checkboxValues.overnight === false &&
        answeredModal === false
      ) {
        removeFocus();
        handleModalOpening("overtime");
        setIsModalOpen(true);

        return;
      } else if (startTime > stopTime && checkboxValues.overnight === false) {
        setParHours("Enter Valid Stop Time");
        return;
      }

      hours = 24 - Number(startHours) + Number(endHours);

      if (hours > 23) {
        hours -= 24;
      }

      if (Number(endMinutes) < Number(startMinutes)) {
        hours--;
      }

      if (hours === -1) {
        hours = 0;
      }

      setParHours(`${hours}:${minutes}`);
    } else if (startHours !== "" && endHours !== "") {
      setParHours(`${hours}:${minutes}`);
    } else {
      setParHours("");
    }
  }, [
    startTime,
    stopTime,
    endHours,
    startHours,
    answeredModal,
    checkboxValues.overnight,
    setIsModalOpen,
    handleModalOpening,
  ]);

  useEffect(() => {
    if (
      !(startTime === "" || stopTime === "") &&
      startTime !== "" &&
      stopTime !== "" &&
      stopTime < startTime &&
      timeChange
    ) {
      setTimeChange(false);
    }
  }, [startTime, stopTime, timeChange, handleModalOpening]);

  useEffect(() => {
    if (lastSelected.current && !isModalOpen) {
      const selectedItem = phaseCodesRef.current.find(
        (el) => el?.id === lastSelected.current
      );

      if (selectedItem && bottomPhaseAdd) {
        const scrollTimer = setTimeout(function () {
          selectedItem.scrollIntoView();
          window.scrollBy(0, -100);
        }, 100);
        setBottomPhaseAdd(false);
        return () => clearTimeout(scrollTimer);
      }
    }

    if (!isModalOpen) {
      setCurrentMatId("");
    }
  }, [isModalOpen, phaseCodesRef, bottomPhaseAdd, setBottomPhaseAdd]);

  useEffect(() => {
    if (
      parFormDataState.dcrNumber !== "" &&
      modalDescription === "edit-existing-par" &&
      isModalOpen
    ) {
      const checkboxValues = {};
      const checks = ["issues", "incidents", "injuries"];

      checkboxValues["subcontractor"] =
        parFormDataState.subcontractor[0]?.name === "none" ? false : true;

      checks.forEach((check) => {
        checkboxValues[check] =
          parFormDataState[check] === "Nothing to Report." ? false : true;
      });

      checkboxValues["timeMaterials"] =
        parFormDataState.matCustomerCode === "" ? false : true;

      checkboxValues["overnight"] = startTime > stopTime ? true : false;

      if (parFormDataState.material[0]) {
        checkboxValues["material"] =
          parFormDataState.material[0].id === 0 ? false : true;
      } else {
        checkboxValues["material"] = false;
      }

      localStorage.setItem("checkboxValues", JSON.stringify(checkboxValues));
      setCheckboxValues(checkboxValues);
    }
  }, [
    parFormDataState.dcrNumber,
    modalDescription,
    isModalOpen,
    parFormDataState,
    setCheckboxValues,
    startTime,
    stopTime,
  ]);

  useEffect(() => {
    if (idb?.length > 0) {
      setSelectedIssue(idb.at(-1));
    } else {
      setSelectedIssue(null);
    }
  }, [idb]);

  const renderIssues = () => {
    return idb.map((row, idx) => {
      return (
        <Issues
          handleModalOpening={handleModalOpening}
          key={row.issueNumber}
          {...row}
          setSelectedIssue={setSelectedIssue}
          setEditIssue={setEditIssue}
        />
      );
    });
  };

  useEffect(() => {
    if (parFormDataState.dcrNumber && !location.state?.bypassWarningModal) {
      handleModalOpening("edit-existing-par");
    }
  }, [parFormDataState.dcrNumber, location.state, handleModalOpening]);

  const movePerDiem = () => {
    const listOfKeys = Object.keys(phaseCodes);
    if (listOfKeys.includes("012130-00-00")) {
      const perDiemPhaseCodeIndex = listOfKeys.indexOf("012130-00-00");

      listOfKeys.splice(perDiemPhaseCodeIndex, 1);

      listOfKeys.push("012130-00-00");

      return listOfKeys;
    } else {
      return listOfKeys;
    }
  };

  const formatDateYearEnd = (date) => {
    const splitDate = date.split("-");
    const year = splitDate[0].slice(2);
    const arrangedDate = `${splitDate[1]}/${splitDate[2]}/${year}`;

    return arrangedDate;
  };

  useEffect(() => {
    const newDate = formatDateYearEnd(formattedDate);
    if (formattedDate === "") {
      basicFieldUpdate("", "date", "updateInputField");
    } else {
      basicFieldUpdate(newDate, "date", "updateInputField");
    }
  }, [formattedDate, basicFieldUpdate]);

  const handleTimeMaterialsCheck = () => {
    if (checkboxValues.timeMaterials) {
      basicFieldUpdate("", "matCustomer", "updateInputField");
      basicFieldUpdate("", "matCustomerCode", "updateInputField");
      basicFieldUpdate("", "matOsr", "updateInputField");
      basicFieldUpdate("", "matJobsite", "updateInputField");
    }

    setCheckboxValues((c) => {
      return { ...c, timeMaterials: !c.timeMaterials };
    });
  };

  const handleCheckmarkChange = (type, allCheckboxValues) => {
    if (type === "incidents" || "injuries") {
      const titledType = type.charAt(0).toUpperCase() + type.slice(1);
      const checkmarkLookup = allCheckboxValues[type] ? "noReport" : "report";
      const checkmarkBoolean = {
        report: `One or more ${titledType} were reported in accordance with SOP's.`,
        noReport: `Nothing to Report.`,
      };
      basicFieldUpdate(
        checkmarkBoolean[checkmarkLookup],
        type,
        "updateInputField"
      );
    } else if (type === "issues" && allCheckboxValues[type] === false) {
      basicFieldUpdate("", type, "updateInputField");
    }

    setCheckboxValues((allCheckboxValues) => {
      return { ...allCheckboxValues, [type]: !allCheckboxValues[type] };
    });
  };

  useAbortEffect((signal) => {
    const pathName = window.location.pathname;
    const pathNameArray = pathName.split("+");
    const dcrNumber = pathNameArray[1];
    if (dcrNumber) {
      const getParReportPromise = new Promise((resolve, reject) => {
        asyncAPICall(
          `api:W2oHkD04/partblmain/parNumber?parNumber=${dcrNumber}`,
          "GET",
          null,
          null,
          (data) => {
            parDispatch({
              type: "setParForm",
              form: data,
            });
            resolve(true);
          },
          (err) => reject("Error PAR report", err),
          signal
        );
      });

      getParReportPromise.catch(console.error);
    }
  }, []);

  return (
    <div className="par-information-container">
      <div className="header-wrapper">
        <div className="reset-information-wrapper">
          <a
            className="instructions-link"
            href="https://scribehow.com/shared/PAR_Submitter__gurwseo3Rxu1Hu-diK0G9Q"
            target="_blank"
            rel="noreferrer"
          >
            PAR Instructions
          </a>

          <div
            className="reset-par"
            onClick={() => {
              handleModalOpening("reset-par");
            }}
          >
            Reset PAR
          </div>
        </div>

        <div className="par-message">Status: {parFormDataState.dcrStatus}</div>
        <div className="par-message">{lastSaved}</div>
        <h1 className="par-header">PROJECT ACTIVITY RECORD</h1>
        <div>
          {parFormDataState.statusComments?.length > 0 && (
            <ApproverChanges parData={parFormDataState} color={"red"} />
          )}
        </div>
        <button
          className={`job-information ${
            jobInfoComplete ? "" : "header-alternate"
          }`}
          onClick={() => setHeaderToggle((prevToggle) => !prevToggle)}
        >
          JOB INFORMATION
        </button>
      </div>
      <div
        className={`main-content-container ${
          headerToggle ? "hide-header" : ""
        }`}
      >
        <div className="job-information-wrapper">
          <ParInput
            type="date"
            label="Date"
            customClass="date-container"
            value={formattedDate}
            onChange={(e) => {
              const today = new Date();
              const formattedToday = new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDate()
              );
              const parDate = new Date(e.target.value);

              if (parDate <= formattedToday) {
                setFormattedDate(e.target.value);
              } else if (parDate > formattedToday) {
                errorToast("Cannot Submit Future Date");
                setFormattedDate("");
              }
            }}
            disabled={resetting}
          />

          <div className="par-button-wrapper">
            <button
              className="add-job"
              onClick={() => {
                handleModalOpening("add-job");
              }}
              disabled={resetting}
            >
              Add Job Number
            </button>
          </div>

          <div className="job-number-wrapper">
            <label htmlFor="job">Job Number</label>
            <input type="text" value={jobNumber} name="job" id="job" readOnly />
          </div>

          <ParSelect
            label="Approver"
            customClass="pfs-container"
            idKey="Approver"
            options={pfsList}
            value={pfs}
            displayKeys={["FullName"]}
            onChange={(e) => {
              parDispatch({
                type: "updatePfs",
                payload: {
                  pfs: Number(e.target.value),
                },
              });
            }}
            disabled={resetting}
          />

          <div className="submitter-wrapper">
            <label htmlFor="foreman">Submitter</label>
            <input
              type="text"
              value={submitterName}
              name="foreman"
              id="foreman"
              readOnly
            />
          </div>

          <div className="par-button-wrapper">
            <button
              className="autofill-phase-code"
              onClick={() => {
                handleModalOpening("autofill");
              }}
              disabled={!autofillData.length > 0}
            >
              Autofill Phase Codes
            </button>

            <button
              className="add-phase-code"
              onClick={() => {
                setBottomPhaseAdd(false);
                handleModalOpening("phase-code");
              }}
              disabled={!jobNumber.length > 0}
            >
              Add Phase Codes
            </button>

            <button
              className="employee-autofill"
              onClick={() => {
                setPrevAllPhaseEmployees([...allPhaseEmployees]);
                handleModalOpening("add-employee");
              }}
              disabled={!jobNumber.length > 0}
            >
              All Phase Employee Add
            </button>

            <button
              className="equipment-autofill"
              onClick={() => {
                setPrevAllPhaseEquipment([...allPhaseEquipment]);
                handleModalOpening("add-equipment");
              }}
              disabled={!jobNumber.length > 0}
            >
              All Phase Equipment Add
            </button>
          </div>
          <ParInput
            type="text"
            label="Safety Topic"
            customClass="safety-topic-container"
            value={parFormDataState.safetyTopic}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "safetyTopic",
                "updateInputField"
              )
            }
            disabled={resetting}
          />
        </div>

        <div className="job-information-wrapper">
          <div className="weather-autofill-container">
            <button
              className="weather-link"
              onClick={() => getWeather()}
              disabled={!isLoading && !jobLocation}
            >
              {!jobLocation ? (
                <FontAwesomeIcon icon="fa fa-spinner" />
              ) : (
                <FontAwesomeIcon icon="fa fa-cloud-sun-rain" />
              )}
              {!jobLocation ? " No Job Location" : " Weather Autofill"}
            </button>
          </div>

          <ParInput
            type="number"
            label="Temp High"
            customClass="temperatureHigh-container"
            value={temperatureHigh}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "temperatureHigh",
                "updateInputField"
              )
            }
            disabled={resetting}
          />

          <ParInput
            type="number"
            label="Temp Low"
            customClass="temperatureLow-container"
            value={temperatureLow}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "temperatureLow",
                "updateInputField"
              )
            }
            disabled={resetting}
          />

          <ParInput
            type="text"
            label="Wind"
            customClass="wind-container"
            value={wind}
            onChange={(e) =>
              basicFieldUpdate(e.target.value, "wind", "updateInputField")
            }
            disabled={resetting}
          />

          <ParInput
            type="text"
            label="Precipitation"
            customClass="precipitation-container"
            value={precipitation}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value,
                "precipitation",
                "updateInputField"
              )
            }
            disabled={resetting}
          />

          <ParTextArea
            customClass="forecast-container"
            labelName="Forecast"
            rows={4}
            cols={10}
            type="text"
            value={forecast}
            onChange={(e) =>
              basicFieldUpdate(
                e.target.value.slice(0, 76),
                "forecast",
                "updateInputField"
              )
            }
            disabled={resetting}
          />

          <ParInput
            type="time"
            label="Start Time"
            customClass="start-time-container"
            value={startTime}
            onChange={(e) => {
              basicFieldUpdate(e.target.value, "startTime", "updateInputField");
            }}
            onBlur={() => {
              setTimeChange(true);
              if (checkboxValues.overnight === true && answeredModal === null) {
                handleModalOpening("overtime");
                setCheckboxValues((c) => ({ ...c, overnight: false }));
              }
            }}
            disabled={resetting}
          />

          <div className="stop-time-container">
            <label>Stop Time</label>
            <input
              type="time"
              value={stopTime}
              ref={stopTimeRef}
              onChange={(e) => {
                basicFieldUpdate(
                  e.target.value,
                  "stopTime",
                  "updateInputField"
                );
              }}
              onBlur={() => {
                setTimeChange(true);

                if (
                  checkboxValues.overnight === true &&
                  answeredModal === null
                ) {
                  handleModalOpening("overtime");
                  setCheckboxValues((c) => ({ ...c, overnight: false }));
                }
              }}
              disabled={resetting}
            />
          </div>
          <div className="overnight-checkbox">
            <input
              id="overnight"
              type="checkbox"
              checked={checkboxValues.overnight}
              onChange={handleOvernightCheck}
            />
            <label htmlFor="overnight">Overnight Hours?</label>
          </div>
          <div className="hours-worked-container">
            <label htmlFor="hours-worked">Hours Worked</label>
            <input
              type="text"
              value={parHours}
              name="hours-worked"
              id="hours-worked"
              readOnly
            />
          </div>
        </div>
      </div>
      <div className={"notes-section-wrapper"}>
        <NotesSection
          basicFieldUpdate={basicFieldUpdate}
          resetting={resetting}
          showJournalEntries={showJournalEntries}
          setShowJournalEntries={setShowJournalEntries}
        />

        <div className="subcontractor-disclaimer">
          ***The entries in these sections will never be a substitute for
          completing the proper documentation or notifying the correct
          individuals as defined in current BHI GOM policies and procedures
          regarding incident and injury reporting. If you have questions
          regarding these procedures, please contact your supervisor
          immediately.***
        </div>
      </div>
      <div className="par-checkbox-container">
        <div className="checkbox-item">
          <div className="checkbox-wrapper">
            <ParCheckbox
              labelClass="checkbox-label"
              inputClass="checkbox"
              checked={checkboxValues.issues}
              disabled={idb?.length > 0 && idb[0]?.issueNumber !== 0}
              onChange={handleIdbChange}
              spanClass="checkmark"
              labelName="Issues?"
            />
          </div>

          <div className="checkbox-wrapper">
            <ParCheckbox
              labelClass="checkbox-label"
              inputClass="checkbox"
              checked={checkboxValues.incidents}
              onChange={() => {
                handleCheckmarkChange("incidents", checkboxValues);
              }}
              spanClass="checkmark"
              labelName="Incidents?"
            />
          </div>

          <div className="checkbox-wrapper">
            <ParCheckbox
              labelClass="checkbox-label"
              inputClass="checkbox"
              checked={checkboxValues.injuries}
              onChange={() => {
                handleCheckmarkChange("injuries", checkboxValues);
              }}
              spanClass="checkmark"
              labelName="Injuries?"
            />
          </div>

          <div className="checkbox-wrapper">
            <ParCheckbox
              labelClass="checkbox-label"
              inputClass="checkbox"
              checked={checkboxValues.subcontractor}
              disabled={subcontractor[0]?.id !== 0 && subcontractor.length > 0}
              onChange={() => {
                if (!checkboxValues.subcontractor) {
                  basicFieldUpdate([], "subcontractor", "updateInputField");
                }
                if (
                  subcontractor.length === 0 &&
                  checkboxValues.subcontractor
                ) {
                  const emptySubcontractor = {
                    id: 0,
                    name: "none",
                    description:
                      "None of our subcontractors were observed this period.",
                    dailyHours: 0,
                    employeeCount: 0,
                    type: "none",
                  };
                  basicFieldUpdate(
                    [emptySubcontractor],
                    "subcontractor",
                    "updateInputField"
                  );
                }
                setCheckboxValues((c) => {
                  return { ...c, subcontractor: !c.subcontractor };
                });
              }}
              spanClass="checkmark"
              labelName="Subcontractors?"
            />
          </div>

          <div className="checkbox-wrapper">
            <ParCheckbox
              labelClass="checkbox-label"
              inputClass="checkbox"
              checked={checkboxValues.timeMaterials}
              disabled={false}
              onChange={() => {
                if (
                  !checkboxValues.timeMaterials ||
                  (parFormDataState.matCustomerCode === "" &&
                    parFormDataState.matOsr === "" &&
                    parFormDataState.matJobsite === "")
                ) {
                  handleTimeMaterialsCheck();
                }
              }}
              spanClass="checkmark"
              labelName="Time & Material?"
            />
          </div>

          <div className="checkbox-wrapper">
            <ParCheckbox
              labelClass="checkbox-label"
              inputClass="checkbox"
              checked={checkboxValues.material}
              disabled={material?.length > 0 && material[0]?.id !== 0}
              onChange={() => {
                setCheckboxValues((c) => {
                  if (c.material) {
                    const emptyMaterial = {
                      id: 0,
                      phaseCode: "",
                      phaseDesc: "",
                      description:
                        "None of our materials were observed this period.",
                      units: "0.00",
                      cost: "0.00",
                      total: "0.00",
                    };
                    basicFieldUpdate(
                      [emptyMaterial],
                      "material",
                      "updateInputField"
                    );
                  } else if (!c.material) {
                    basicFieldUpdate([], "material", "updateInputField");
                  }
                  return { ...c, material: !c.material };
                });
              }}
              spanClass="checkmark"
              labelName="Materials?"
            />
          </div>
        </div>
      </div>
      <div className="problems-holder">
        {checkboxValues.issues && (
          <div className="single-problem-container">
            <div className="heading-wrapper">
              <button
                className="issues"
                onClick={() => setShowIssues((prev) => !prev)}
              >
                Issues
              </button>
              <button
                className="add-issue-btn"
                onClick={() => {
                  setEditIssue({});
                  handleModalOpening("add-issue");
                }}
              >
                New Issue
              </button>
            </div>

            {showIssues &&
              (idb?.length > 0 ? (
                <ParTable
                  tableClass="issue-table"
                  tableHeaders={[
                    { class: "issue-header type", name: "Type" },
                    { class: "issue-header date", name: "Date" },
                    {
                      class: "issue-header description",
                      name: "Description",
                    },
                    { class: "issue-header edit", name: "" },
                    { class: "issue-header delete", name: "" },
                  ]}
                  tableRows={renderIssues()}
                />
              ) : (
                <h3>No Issues Entered</h3>
              ))}
          </div>
        )}

        {checkboxValues.incidents && (
          <ParTextArea
            customClass="single-problem-container"
            labelName="Incidents"
            rows="3"
            cols="30"
            value={incidents}
            readOnly
          />
        )}
        {checkboxValues.injuries && (
          <ParTextArea
            customClass="single-problem-container"
            labelName="Injuries"
            rows="3"
            cols="30"
            value={injuries}
            readOnly
          />
        )}

        {checkboxValues.subcontractor && (
          <Subcontractors
            handleModalOpening={handleModalOpening}
            setSelectedSubcontractor={setSelectedSubcontractor}
            approvedSubcontractors={approvedSubcontractors}
            subcontractor={subcontractor}
            setHeaderToggle={setHeaderToggle}
            basicFieldUpdate={basicFieldUpdate}
          />
        )}

        {checkboxValues.timeMaterials && (
          <TimeMaterials
            resetting={resetting}
            basicFieldUpdate={basicFieldUpdate}
            jobNumber={jobNumber}
          />
        )}

        {checkboxValues.material && (
          <Materials
            setSelectedMaterial={setSelectedMaterial}
            setCurrentMatId={setCurrentMatId}
            handleModalOpening={handleModalOpening}
            basicFieldUpdate={basicFieldUpdate}
            uomArray={uomArray}
            setBottomPhaseAdd={setBottomPhaseAdd}
            setHeaderToggle={setHeaderToggle}
            jobNumber={jobNumber}
            material={material}
          />
        )}
      </div>
      <div className="phasecode-table-container">
        {movePerDiem().map((item, idx) => {
          return (
            <PhaseCodeItem
              key={idx}
              ref={phaseCodesRef}
              item={phaseCodes[item]}
              phaseNum={item}
              parHours={parHours}
              removePhase={() => removePhase(item)}
              perDiemAmount={perDiemAmount}
              perDiem={perDiem}
            />
          );
        })}
      </div>
      <Modal
        isModalOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        modalDescription={modalDescription}
        modalParts={{
          "remove-issue": {
            modalContent:
              window.screen.width <= 650
                ? {
                    width: "350px",
                    height: "450px",
                    backgroundColor: "#EAE9E9",
                    textAlign: "center",
                  }
                : {
                    width: "500px",
                    height: "300px",
                    backgroundColor: "#EAE9E9",
                    textAlign: "center",
                  },
            modalForm: (
              <RemoveIssueForm
                setIsModalOpen={setIsModalOpen}
                deleteRow={deleteIdb}
                selectedItem={selectedIssue}
              />
            ),
          },
          "phase-code": {
            modalContent:
              window.screen.width <= 650
                ? { width: "350px", height: "450px" }
                : { width: "760px", height: "600px" },
            modalForm: (
              <PhaseCodeForm
                setIsModalOpen={setIsModalOpen}
                bottomPhaseAdd={bottomPhaseAdd}
                setCurrentMatId={setCurrentMatId}
                currentMatId={currentMatId}
                lastSelected={lastSelected}
                allPhaseEmployees={allPhaseEmployees}
                allPhaseEquipment={allPhaseEquipment}
              />
            ),
          },
          autofill: {
            modalContent:
              window.screen.width <= 650
                ? { width: "350px", height: "450px" }
                : { width: "760px", height: "600px" },
            modalForm: (
              <Autofill
                setIsModalOpen={setIsModalOpen}
                jobNumber={jobNumber}
                autofillData={autofillData}
              />
            ),
          },
          "add-employee": {
            modalContent:
              window.screen.width <= 650
                ? { width: "350px", height: "450px", textAlign: "center" }
                : { width: "760px", height: "600px" },
            modalForm: (
              <AddEmployee
                setIsModalOpen={setIsModalOpen}
                hoursWorked={"0"}
                perDiem={perDiem}
                perDiemAmount={perDiemAmount}
                allPhaseEmployees={allPhaseEmployees}
                setAllPhaseEmployees={setAllPhaseEmployees}
                prevAllPhaseEmployees={prevAllPhaseEmployees}
              />
            ),
          },
          "add-job": {
            modalContent:
              window.screen.width <= 650
                ? { width: "350px", height: "450px" }
                : { width: "760px", height: "600px" },
            modalForm: (
              <JobForm setIsModalOpen={setIsModalOpen} resetPar={resetPar} />
            ),
          },
          "add-equipment": {
            modalContent:
              window.screen.width <= 650
                ? { width: "350px", height: "450px", textAlign: "center" }
                : { width: "760px", height: "600px" },
            modalForm: (
              <EquipmentForm
                setIsModalOpen={setIsModalOpen}
                hoursWorked={"0"}
                allPhaseEquipment={allPhaseEquipment}
                setAllPhaseEquipment={setAllPhaseEquipment}
                prevAllPhaseEquipment={prevAllPhaseEquipment}
              />
            ),
          },
          "add-issue": {
            modalContent:
              window.screen.width <= 650
                ? {
                    width: "350px",
                    height: "450px",
                    backgroundColor: "#EAE9E9",
                  }
                : {
                    width: "760px",
                    height: "425px",
                    backgroundColor: "#EAE9E9",
                    textAlign: "center",
                    top: "50%",
                  },
            modalForm: (
              <AddIssueForm
                setIsModalOpen={setIsModalOpen}
                deleteRow={deleteIdb}
                addRow={addIdb}
                updateRow={updateIdb}
                issueNumber={selectedIssue?.issueNumber}
                jobNumber={jobNumber}
                jobDescription={jobDescription}
                idb={parFormDataState.idb}
                foreman={foreman}
                handleModalOpening={handleModalOpening}
                editIssue={editIssue}
                setShowIssues={setShowIssues}
              />
            ),
          },
          "remove-material": {
            modalContent: "verify-modal-styles",
            modalForm: (
              <RemoveMaterialForm
                setIsModalOpen={setIsModalOpen}
                deleteRow={deleteMaterial}
                selectedItem={selectedMaterial.description}
              />
            ),
          },
          "remove-employee": {
            modalContent: "verify-modal-styles",
            modalForm: (
              <RemoveEmployeeForm
                setIsModalOpen={setIsModalOpen}
                deleteRow={deleteSubcontractor}
                selectedItem={selectedSubcontractor.name}
              />
            ),
          },
          "reset-par": {
            modalContent: "verify-modal-styles",
            modalForm: (
              <ResetParForm
                setIsModalOpen={setIsModalOpen}
                setResetting={setResetting}
                resetPar={resetPar}
              />
            ),
          },
          "edit-existing-par": {
            modalContent: "verify-modal-styles",
            modalForm: (
              <EditExistingForm
                setIsModalOpen={setIsModalOpen}
                setResetting={setResetting}
                resetPar={resetPar}
              />
            ),
          },
          verify: {
            modalContent:
              window.screen.width <= 650
                ? {
                    width: "325px",
                    height: "247px",
                    padding: "0px 20px",
                    backgroundColor: "#EAE9E9",
                    border: "2px solid black",
                    textAlign: "center",
                  }
                : {
                    width: "375px",
                    height: "247px",
                    padding: "0px 20px",
                    backgroundColor: "#EAE9E9",
                    border: "2px solid black",
                    textAlign: "center",
                  },
            modalForm: (
              <VerifyForm
                message={`Would you like to add another Issues record`}
                handleConfirm={() => handleAnotherIssueYes()}
                handleCancel={() => setIsModalOpen(false)}
              />
            ),
          },
          "verify-no-issues": {
            modalContent:
              window.screen.width <= 650
                ? {
                    width: "325px",
                    height: "247px",
                    padding: "0px 20px",
                    backgroundColor: "#EAE9E9",
                    border: "2px solid black",
                    textAlign: "center",
                  }
                : {
                    width: "375px",
                    height: "247px",
                    padding: "0px 20px",
                    backgroundColor: "#EAE9E9",
                    border: "2px solid black",
                    textAlign: "center",
                  },
            modalForm: (
              <VerifyForm
                message={`Are you sure there were no ${
                  checkboxType === "issues" ? "Issues records" : ""
                } with this PAR?`}
                navigation={"/par-verification/par"}
                handleCancel={handleVerifyNoClick}
                handleConfirm={handleVerifyYesClick}
              />
            ),
          },
          overtime: {
            modalContent: {
              width: "275px",
              height: "247px",
              backgroundColor: "#EAE9E9",
              textAlign: "center",
            },
            modalForm: <IsOverNight handleIsOverNight={handleIsOverNight} />,
          },
        }}
      />
    </div>
  );
};

export default ParJobInformation;
