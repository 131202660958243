const DailyTicketForm = (props) => {
  const { setIsModalOpen, handlePrint } = props;

  const handleClick = () => {
    const closeModal = new Promise((resolve, reject) => {
      resolve(setIsModalOpen(false));
      reject("There was an error printing.");
    });

    closeModal
      .then(() => {
        handlePrint();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className="remove-item-modal-container">
      <h3 className="warning-header">
        Warning: You are PRINTING internal information, not intended for
        externally sharing.
      </h3>

      <button className="modal-button" onClick={handleClick}>
        PRINT
      </button>

      <button className="modal-button" onClick={() => setIsModalOpen(false)}>
        CANCEL
      </button>
    </div>
  );
};

export default DailyTicketForm;
