import { useContext, useState } from "react";

import { ParDataContext } from "../defaultContainer";
import SubcontractorItem from "./SubcontractorItem";

const Subcontractors = (props) => {
  const {
    handleModalOpening,
    setSelectedSubcontractor,
    approvedSubcontractors,
    subcontractor,
  } = props;
  const { parDispatch } = useContext(ParDataContext);
  const [showSubcontractor, setShowSubcontractor] = useState(true);

  const addSubcontractor = (subcontractorType) => {
    parDispatch({
      type: "addSubcontractor",
      subcontractorType,
    });
  };

  const updateSubcontractor = (e, id, updateField) => {
    parDispatch({
      type: "updateSubcontractor",
      updateField: updateField,
      value: e.target.value,
      id: id,
    });
  };

  const renderSubcontractors = () => {
    return subcontractor.map((row) => {
      return (
        <SubcontractorItem
          handleModalOpening={handleModalOpening}
          key={row.id}
          {...row}
          updateSubcontractor={updateSubcontractor}
          setSelectedSubcontractor={setSelectedSubcontractor}
          approvedSubcontractors={approvedSubcontractors}
        />
      );
    });
  };
  return (
    <div>
      <div className="heading-wrapper">
        <button
          className="section-label"
          onClick={() => setShowSubcontractor((prev) => !prev)}
        >
          Subcontractors
        </button>
      </div>
      {showSubcontractor && (
        <div className="single-problem-container">
          <div className="table-container ">
            <table>
              <thead>
                <tr className="table-head">
                  <th className="padding-20"></th>
                  <th className="subcontractor-detail"></th>
                </tr>
              </thead>
              <tbody>{renderSubcontractors()}</tbody>
            </table>
          </div>
          <div className="subcontractor-container">
            <button
              className="bhi-subcontractor"
              onClick={() => addSubcontractor("bhi")}
            >
              Add BHI Subcontractor
            </button>
            <button
              className="supplemental-subcontractor"
              onClick={() => addSubcontractor("supplemental")}
            >
              Add Supplemental Subcontractor
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Subcontractors;
