import { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Cookies from "js-cookie";
import Logo from "./img/logo.svg";
import SecurityWrapper, { roles } from "./util/securityWrapper";

export default function Header(props) {
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  useEffect(() => {
    let session_token_from_cookie = Cookies.get("session_token");
    if (session_token_from_cookie) {
      props.setSessionToken(session_token_from_cookie);
    } else {
      props.setSessionToken(null);
    }
  }, [props]);

  return (
    <div className="navbar-wrapper">
      <div className="left-column">
        <Link
          className="logo-wrapper nav-item"
          to="/overview"
          onClick={() => {
            setIsNavExpanded(false);
          }}
        >
          <img src={Logo} alt="BHI Main Logo" height="100px"></img>
        </Link>
      </div>

      <div className="right-column">
        <button
          className="hamburger-menu"
          onClick={() => {
            setIsNavExpanded(!isNavExpanded);
          }}
        >
          <div className="page-link nav-item"></div>
        </button>

        <div
          className={
            isNavExpanded ? "navigation-menu expanded" : "navigation-menu"
          }
        >
          <NavLink
            to="/overview"
            onClick={() => {
              setIsNavExpanded(false);
            }}
          >
            <div className="page-link nav-item">OVERVIEW</div>
          </NavLink>

          <NavLink
            to="/new-par"
            onClick={() => {
              setIsNavExpanded(false);
            }}
          >
            <div className="page-link nav-item">NEW PAR</div>
          </NavLink>

          <NavLink
            exact
            to="/approvals"
            onClick={() => {
              setIsNavExpanded(false);
            }}
          >
            <div className="page-link nav-item">APPROVALS</div>
          </NavLink>

          <SecurityWrapper roles={[...roles.admin, ...roles.pca]}>
            <NavLink
              exact
              to="/alerts"
              onClick={() => {
                setIsNavExpanded(false);
              }}
            >
              <div className="page-link nav-item">ALERTS</div>
            </NavLink>
          </SecurityWrapper>

          <NavLink exact to="/login">
            <div className="page-link nav-item">LOGOUT</div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
