import { useState, useEffect, useContext } from "react";
import Truncate from "../helpers/Truncate";

import { getTotalHours } from "../helpers/totalHours";
import { ParDataContext } from "../../defaultContainer";

const EmployeeItem = (props) => {
  const {
    selectedEmployee,
    setSelectedData,
    data,
    hoursWorked,
    selectedData,
    phaseNum,
    perDiemAmount,
    allPhaseEmployees,
    setAllPhaseEmployees,
    setEmployeeInfo,
  } = props;
  const [selected, setSelected] = useState(false);
  const { parFormDataState } = useContext(ParDataContext);
  const { FirstName, LastName, MidName, employeeID } = data;

  useEffect(() => {
    if (selectedEmployee) {
      setSelected(true);
    }
  }, [
    FirstName,
    selectedEmployee,
    LastName,
    hoursWorked,
    employeeID,
    setSelectedData,
    perDiemAmount,
    parFormDataState,
    phaseNum,
    selected,
  ]);

  useEffect(() => {
    allPhaseEmployees?.forEach((employee) => {
      if (employee.id === employeeID && !setEmployeeInfo) {
        setSelected(true);
      }
    });
  });

  useEffect(() => {
    if (!allPhaseEmployees && !selectedData.includes(data)) {
      setSelected(false);
    }
  }, [data, selectedData, allPhaseEmployees]);

  const handleSelectedList = () => {
    setSelected((s) => !s);
    if (!selected) {
      const employeeData = {
        name: `${FirstName} ${LastName}`,
        id: employeeID,
        laborNotes: "",
        perDiemAmount: "",
        perDiem: false,
        combinedPhaseTime: getTotalHours(employeeID, parFormDataState, "labor"),
        hoursWorked: hoursWorked,
      };
      const addEmployee = setEmployeeInfo
        ? [employeeData]
        : [...selectedData, employeeData];
      setSelectedData(addEmployee);
      if (!phaseNum && !setEmployeeInfo) {
        const allPhasesAdd = [...allPhaseEmployees, employeeData];
        setAllPhaseEmployees(allPhasesAdd);
      }
    } else {
      const removeEmployee = selectedData.filter((employee) => {
        return employee.name !== `${FirstName} ${LastName}`;
      });
      setSelectedData(removeEmployee);
      if (!phaseNum && !setEmployeeInfo) {
        const removeFromAllEmployee = allPhaseEmployees.filter((employee) => {
          return employee.name !== `${FirstName} ${LastName}`;
        });
        setAllPhaseEmployees(removeFromAllEmployee);
      }
    }
  };

  return (
    <div
      className="employee-item-container"
      data-title={`${FirstName} ${MidName !== null ? MidName : ""} ${LastName}`}
    >
      <div
        onClick={handleSelectedList}
        className={`${selected ? "selected" : "not-selected"}`}
      >
        <Truncate
          content={`${FirstName} ${
            MidName !== null ? MidName : ""
          } ${LastName}`}
          maxChar={17}
        />
      </div>
    </div>
  );
};

export default EmployeeItem;
