import { useEffect, useState, useContext } from "react";

import useDebounce from "../hooks/useDebounce";
import asyncAPICall from "../../util/apiWrapper";
import useAbortEffect from "../hooks/useAbortEffect";
import Truncate from "../helpers/Truncate";
import { ParDataContext } from "../../defaultContainer";
import { infoToast } from "../../util/toastNotifications";
import LoadingSpinner from "../helpers/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const JobForm = (props) => {
  const [jobNumbers, setJobNumbers] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const [filter, setFilter] = useState([]);
  const [selectedJob, setSelectedJob] = useState({});
  const [hideButton, setHideButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const jobSearchDebounce = useDebounce(searchResult);
  const { parFormDataState, parDispatch } = useContext(ParDataContext);
  const { resetPar, setIsModalOpen } = props;

  const submit = () => {
    if (selectedJob.Project === parFormDataState.jobNumber) {
      infoToast("You have already selected this job number.");
      setIsModalOpen(false);
    } else if (!selectedJob.Project) {
      setIsModalOpen(false);
    } else {
      resetPar("jobNumber");

      setHideButton(true);

      parDispatch({
        type: "updateInputField",
        payload: {
          field: "jobNumber",
          value: `${selectedJob.Project}`,
        },
      });

      parDispatch({
        type: "updateInputField",
        payload: {
          field: "jobDescription",
          value: `${selectedJob.Project_Name}`,
        },
      });

      parDispatch({
        type: "updateInputField",
        payload: {
          field: "jobCertified",
          value: `${selectedJob.Certified}`,
        },
      });
    }
  };

  useAbortEffect((signal) => {
    function getJobs() {
      asyncAPICall(
        "api:KE7MCNYf/vprojectswithid",
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            setJobNumbers(data);
            setIsLoading(false);
          }
        },
        (err) => {
          if (!signal.aborted) {
            console.log("Getting Jobs Error", err);
          }
        },
        signal
      );
    }
    getJobs();
  }, []);

  useEffect(() => {
    setFilter(
      jobNumbers.filter(
        (jobNumber) =>
          jobNumber.Project.includes(jobSearchDebounce) ||
          jobNumber.Project_Name.toLowerCase().includes(
            jobSearchDebounce.toLowerCase()
          )
      )
    );
  }, [jobSearchDebounce, jobNumbers]);

  const renderData = () => {
    let listOfProjects = [];

    return filter.map((job) => {
      if (listOfProjects.includes(job.Project)) {
        return null;
      }

      listOfProjects.push(job.Project);

      return (
        <div
          key={job.Project}
          className={
            selectedJob.Project === job.Project
              ? "job-item-wrapper selected"
              : "job-item-wrapper"
          }
          data-title={job.Project_Name}
          onClick={() => {
            if (job.Project === selectedJob.Project) {
              setSelectedJob({});
            } else {
              setSelectedJob(job);
            }
          }}
        >
          <Truncate
            content={`${job.Project} ${job.Project_Name}`}
            maxChar={20}
          />
        </div>
      );
    });
  };

  return (
    <div className="main-job-container">
      <div className="job-wrapper">
        <div className="input-header-wrapper">
          <h2>Select A Job</h2>

          <FontAwesomeIcon className="search-icon" icon="fa fa-search" />

          <input
            value={searchResult}
            type="search"
            onChange={(e) => setSearchResult(e.target.value)}
          />
        </div>

        <hr />

        <div
          className={
            isLoading ? "job-data-wrapper spinner" : "job-data-wrapper"
          }
        >
          {isLoading ? <LoadingSpinner /> : renderData()}
        </div>

        <div className="save-btn-wrapper">
          <button onClick={submit} disabled={hideButton ? true : false}>
            {hideButton ? "Loading" : "Save"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobForm;
