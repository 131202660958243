import Truncate from "../helpers/Truncate";

const RemoveIssueForm = (props) => {
  const { deleteRow, selectedItem, setIsModalOpen } = props;

  return (
    <div className="remove-issue-modal-container">
      <div className="remove-issue-header-wrapper">
        <h3>Are you sure you want to remove this issue?</h3>
      </div>

      <div className="remove-issue-body-wrapper">
        <div className="remove-issue-info-wrapper">
          <div className="remove-issue-info remove-issue-type">
            <strong>Type: </strong>
            <Truncate content={selectedItem.issueType} maxChar={32} />
          </div>

          <div className="remove-issue-info">
            <strong>Date Raised: </strong>
            {selectedItem.dateRaised}
          </div>

          <div className="remove-issue-info">
            <strong>Description: </strong>
            <Truncate content={selectedItem.description} maxChar={200} />
          </div>
        </div>

        <div className="remove-issue-button-wrapper">
          <button className="modal-button" onClick={deleteRow}>
            YES
          </button>

          <button
            className="modal-button"
            onClick={() => setIsModalOpen(false)}
          >
            NO
          </button>
        </div>
      </div>
    </div>
  );
};

export default RemoveIssueForm;
