import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Notifications = (props) => {
  const {
    parReports,
    handleDeletePendingPAR,
    setIsCommentsModalOpen,
    setSelectedPAR,
  } = props;

  const renderAlerts = () => {
    return props.mockAlerts.map((notification) => {
      return (
        <div
          className={`par-box ${notification.color}-gradient`}
          key={notification.id}
        >
          <FontAwesomeIcon className={`fas fa-${notification.icon}`} />

          <h3>{notification.date}</h3>

          <h4 style={{ marginLeft: "0px" }}>{notification.message}</h4>
        </div>
      );
    });
  };

  const renderApprovals = () => {
    const reportsCopy = [...parReports];
    let sortedReports = [];

    reportsCopy.sort((a, b) => b.created_at - a.created_at);

    reportsCopy.forEach((e) => {
      if (e.dcrStatus === "Approved") {
        sortedReports.push(e);
      }
    });

    return sortedReports.slice(0, 14).map((parReport) => {
      if (parReport.dcrStatus === "Approved") {
        return (
          <div className="par-box green-gradient" key={parReport.id}>
            <FontAwesomeIcon icon="fas fa-check-circle" />

            <h3>{parReport.date}</h3>
            <h4>{parReport.jobNumber}</h4>

            <Link
              className="overview-report-link"
              to={{
                pathname: `/par-verification/approved+${parReport.dcrNumber}`,
              }}
            >
              View Report
            </Link>

            <h4 className="par-status">{parReport.dcrStatus}</h4>
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderSafetyContact = () => {
    return (
      <div className="par-box purple-gradient">
        <FontAwesomeIcon icon="fas fa-phone" style={{ color: "#47047B" }} />

        <h3 style={{ width: "50%", textAlign: "left" }}>
          {props.safetyContact.name}
        </h3>

        <h4>{props.safetyContact.phone}</h4>
      </div>
    );
  };

  const renderPars = () => {
    const reportsCopy = [...parReports];

    reportsCopy.sort((a, b) => a.created_at - b.created_at);

    return reportsCopy.map((report) => {
      let statusColor = "";
      let statusIcon = "";

      switch (report.dcrStatus) {
        case "Pending":
          statusColor = "blue-gradient";
          statusIcon = "fas fa-circle-notch";
          break;
        case "Submitted":
          statusColor = "blue-gradient";
          statusIcon = "fas fa-check-circle";
          break;
        case "Reject":
          statusColor = "red-gradient";
          statusIcon = "fas fa-exclamation-circle";
          break;
        default:
          statusColor = "";
          break;
      }

      if (statusColor.length > 0) {
        return (
          <div className={`par-box ${statusColor}`} key={report.id}>
            <FontAwesomeIcon icon={`${statusIcon}`} />
            <h3>{report.date}</h3>
            <h4>{report.jobNumber}</h4>

            <div
              className="overview-report-link"
              onClick={() => props.redirect(report)}
            >
              Edit PAR
            </div>

            {report.statusComments.length > 0 && (
              <div
                className="overview-report-link"
                onClick={() => {
                  setIsCommentsModalOpen("reject-comments");
                  setSelectedPAR(report);
                }}
              >
                Comments
              </div>
            )}

            <h4 className="par-status">{report.dcrStatus}</h4>

            {report.dcrStatus !== "Approved" && (
              <FontAwesomeIcon
                className="svg-inline--fa fa-trash"
                icon="fa-trash"
                onClick={() => handleDeletePendingPAR(report)}
              />
            )}
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderWhichType = () => {
    switch (props.type) {
      case "alerts":
        return renderAlerts();

      case "safety":
        return renderSafetyContact();

      case "approvals":
        return renderApprovals();

      case "par":
        return renderPars();

      default:
        return <div>No data to render</div>;
    }
  };

  return (
    <div className="notifications-container">
      <h1 className={`${props.type}`}>{props.header}</h1>

      <div className={`${props.type}-scroll`}>{renderWhichType()}</div>
    </div>
  );
};

export default Notifications;
