import { useEffect, useState, useContext } from "react";

import asyncAPICall from "../../util/apiWrapper";
import useAbortEffect from "../hooks/useAbortEffect";
import { ParDataContext } from "../../defaultContainer";
import LoadingSpinner from "../helpers/LoadingSpinner";

const PrintEmailForm = (props) => {
  const { setIsModalOpen, sendEmail, setSendEmail } = props;
  const { parFormDataState } = useContext(ParDataContext);

  const defaultEmailArray = [
    "industrial42@bhico.com",
    "wireless.comm@bhico.com",
    "bakken.electric@bhico.com",
    "civil.excavation@bhico.com",
    "facilities.pipeline@bhico.com",
    "commercial.electric@bhico.com",
  ];

  const [emailArray, setEmailArray] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  useAbortEffect((signal) => {
    asyncAPICall(
      `api:W2oHkD04/vmultipleapproversbyjob?job=${parFormDataState.jobNumber}`,
      "GET",
      null,
      null,
      (data) => {
        if (data) {
          const emailArray = [...defaultEmailArray];

          data.forEach((singleEmail) => {
            if (!emailArray.includes(singleEmail.Email)) {
              emailArray.push(singleEmail.Email);
            }
          });
          setEmailArray(emailArray);
        }
      },
      (err) => {
        if (!signal.aborted) {
          console.error("Error Getting Approver Emails", err);
        }
      },
      signal
    );
  }, []);

  const renderEmails = () => {
    return emailArray.map((email) => {
      return (
        <option value={email} className="rendered-email" key={email}>
          {email}
        </option>
      );
    });
  };

  useEffect(() => {
    setIsDisabled(() => (sendEmail.length ? false : true));
  }, [sendEmail]);

  return (
    <div className="main-modal-container">
      <div className="inside-container">
        <h1>Select an Email</h1>

        <div className="select-email-wrapper">
          {emailArray.length ? (
            <select
              name="email-select"
              className="email-select"
              defaultValue={sendEmail}
              onChangeCapture={(e) => {
                setSendEmail(e.target.value);
              }}
            >
              <option value="">** Please Select an Email **</option>
              {renderEmails()}
            </select>
          ) : (
            <LoadingSpinner />
          )}
        </div>

        <div className="buttons-wrapper">
          <button
            disabled={isDisabled}
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Save
          </button>

          <button
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PrintEmailForm;
