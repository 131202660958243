import { useState, useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import preventNumScroll from "./helpers/preventNumScroll";

const MaterialItem = (props) => {
  const {
    setSelectedMaterial,
    handleModalOpening,
    setCurrentMatId,
    setBottomPhaseAdd,
    jobNumber,
    phaseCode,
    phaseDesc,
    description,
    units,
    uom,
    uomArray,
    cost,
    total,
    id,
    updateMaterial,
  } = props;

  const [currentUnits, setCurrentUnits] = useState(units);
  const [currentCost, setCurrentCost] = useState(cost);

  const lastUnitsKeyDown = useRef("Backspace");
  const lastCostKeyDown = useRef("Backspace");

  const handleUnitsKeyDown = (e) => {
    e.preventDefault();

    if (e.key === " ") return;

    let isBackspace = false;
    if (isNaN(e.key) && e.key !== "Backspace") {
      return;
    } else if (e.key === "Backspace") {
      isBackspace = true;
    } else if (isNaN(e.key)) {
      return;
    }

    lastCostKeyDown.current = e.key;

    setCurrentUnits((pV) => {
      let splitString = [];
      if (!isBackspace) {
        if (pV === "0.00") {
          return `${pV.slice(0, 3)}${e.key}`;
        } else if (pV.at(0) === "0" && pV.includes("0.0")) {
          const leftOverValue = pV.slice(-1);
          return `${pV.slice(0, 2)}${leftOverValue}${e.key}`;
        } else if (pV.at(0) === "0") {
          const leftOverValueOne = pV.slice(-2, -1);
          const leftOverValueTwo = pV.slice(-1);
          return `${leftOverValueOne}.${leftOverValueTwo}${e.key}`;
        } else {
          splitString = currentUnits.split("");
          splitString.splice(-3, 1);
          splitString.push(e.key);
          splitString.splice(-2, 0, ".");
        }
      } else {
        if (pV.at(0) === "0" && pV.includes("0.0")) {
          return "0.00";
        } else if (pV.at(0) === "0") {
          const leftOverValueOne = pV.slice(-2, -1);
          return `0.0${leftOverValueOne}`;
        } else {
          splitString = currentUnits.split("");
          splitString.splice(-3, 1);
          splitString.pop();
          splitString.splice(-2, 0, ".");
        }
      }
      const filteredSplitString = splitString.filter((char) => char !== ",");

      const joinedString = filteredSplitString.join("");
      const formattedString = parseFloat(joinedString).toLocaleString("en", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      if (joinedString.at(0) === ".") {
        return `0${joinedString}`;
      }
      return formattedString;
    });
  };

  const handleCostKeyDown = (e) => {
    e.preventDefault();

    if (e.key === " ") return;

    let isBackspace = false;
    if (isNaN(e.key) && e.key !== "Backspace") {
      return;
    } else if (e.key === "Backspace") {
      isBackspace = true;
    } else if (isNaN(e.key)) {
      return;
    }

    lastCostKeyDown.current = e.key;

    setCurrentCost((pV) => {
      let splitString = [];
      if (!isBackspace) {
        if (pV === "0.00") {
          return `${pV.slice(0, 3)}${e.key}`;
        } else if (pV.at(0) === "0" && pV.includes("0.0")) {
          const leftOverValue = pV.slice(-1);
          return `${pV.slice(0, 2)}${leftOverValue}${e.key}`;
        } else if (pV.at(0) === "0") {
          const leftOverValueOne = pV.slice(-2, -1);
          const leftOverValueTwo = pV.slice(-1);
          return `${leftOverValueOne}.${leftOverValueTwo}${e.key}`;
        } else {
          splitString = currentCost.split("");
          splitString.splice(-3, 1);
          splitString.push(e.key);
          splitString.splice(-2, 0, ".");
        }
      } else {
        if (pV.at(0) === "0" && pV.includes("0.0")) {
          return "0.00";
        } else if (pV.at(0) === "0") {
          const leftOverValueOne = pV.slice(-2, -1);
          return `0.0${leftOverValueOne}`;
        } else {
          splitString = currentCost.split("");
          splitString.splice(-3, 1);
          splitString.pop();
          splitString.splice(-2, 0, ".");
        }
      }
      const filteredSplitString = splitString.filter((char) => char !== ",");

      const joinedString = filteredSplitString.join("");
      const formattedString = parseFloat(joinedString).toLocaleString("en", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

      if (joinedString.at(0) === ".") {
        return `0${joinedString}`;
      }
      return formattedString;
    });
  };

  const uomOptions = () => {
    if (uom && uomArray.length === 0) {
      return (
        <option key={0} value={uom}>
          {uom}
        </option>
      );
    } else {
      return uomArray.map((m) => {
        return (
          <option key={m.id} value={m.UM}>
            {m.UM}
          </option>
        );
      });
    }
  };

  useEffect(() => {
    if (currentCost.trim().length <= 0) {
      setCurrentCost("0.00");
    }
    if (currentUnits.trim().length <= 0) {
      setCurrentUnits("0.00");
    }
    if (
      currentCost === "0.00" &&
      cost !== "0.00" &&
      lastCostKeyDown.current !== "Backspace"
    ) {
      setCurrentCost(cost);
    }

    if (
      currentUnits === "0.00" &&
      units !== "0.00" &&
      lastUnitsKeyDown.current !== "Backspace"
    ) {
      setCurrentUnits(units);
    }

    if (currentCost !== cost) {
      if (currentCost === "0.00. " || currentCost === "0.00 ") {
        updateMaterial({ target: { value: "0.00" } }, id, "cost");
        setCurrentCost("0.00");
      } else {
        updateMaterial({ target: { value: currentCost } }, id, "cost");
      }
    }

    if (currentUnits !== units) {
      if (currentUnits === "0.00. " || currentUnits === "0.00 ") {
        updateMaterial({ target: { value: "0.00" } }, id, "units");
        setCurrentUnits("0.00");
      } else {
        updateMaterial({ target: { value: currentUnits } }, id, "units");
      }
    }
  }, [currentCost, currentUnits, cost, units, updateMaterial, id]);

  useEffect(() => {
    const tempCost = currentCost.replaceAll(",", "");
    const tempUnits = currentUnits.replaceAll(",", "");
    const currentTotal =
      currentUnits !== "0.00" && currentCost !== "0.00"
        ? (
            parseFloat(tempUnits).toFixed(2) * parseFloat(tempCost).toFixed(2)
          ).toFixed(2)
        : "0.00";

    const eObject = {
      target: {
        value: parseFloat(currentTotal).toLocaleString("en", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }),
      },
    };
    updateMaterial(eObject, id, "total");
  }, [currentUnits, currentCost, id, updateMaterial]);

  useEffect(() => {
    if (cost.slice(-3, -2) !== ".") {
      const eObject = { target: { value: cost.slice(0, -1) } };
      updateMaterial(eObject, id, "cost");
    }
  }, [cost, id, updateMaterial]);

  useEffect(() => {
    if (units.slice(-3, -2) !== ".") {
      const eObject = { target: { value: units.slice(0, -1) } };
      updateMaterial(eObject, id, "units");
    }
  }, [units, id, updateMaterial]);

  return (
    <tr className="material-table-row">
      <td>
        <div className="input-wrapper phase-wrapper">
          <div className="chosen-code">
            {phaseCode ? phaseCode : "(Phase Code)"} {phaseDesc}
          </div>
          <button
            className="mat-phase-add-btn"
            onClick={() => {
              setCurrentMatId(id);
              setBottomPhaseAdd(false);
              handleModalOpening("phase-code");
            }}
            disabled={!jobNumber.length > 0}
          >
            Add Phase Code
          </button>
        </div>
      </td>

      <td>
        <div className="input-wrapper details-wrapper">
          <input
            className="mat-input mat-details"
            type="text"
            placeholder="Material"
            value={description}
            maxLength={19}
            onChange={(e) => updateMaterial(e, id, "description")}
          />
        </div>
      </td>

      <td>
        <div className="input-wrapper units-wrapper">
          <label htmlFor={"units " + id} className="input-label">
            Units:
          </label>
          <input
            className="mat-input mat-number"
            name="units"
            id={"units " + id}
            type="tel"
            placeholder="Units"
            value={currentUnits}
            min="0.00"
            step="0.01"
            onKeyDown={handleUnitsKeyDown}
            onChange={(e) => updateMaterial(e, id, "units")}
            onWheel={preventNumScroll}
          />
        </div>
      </td>

      <td>
        <div className="input-wrapper uom-wrapper">
          <select
            className="mat-input mat-uom"
            value={uom}
            onChange={(e) => updateMaterial(e, id, "uom")}
          >
            <option value="">UoM</option>
            {uomOptions()}
          </select>
        </div>
      </td>

      <td>
        <div className="input-wrapper cost-wrapper">
          <label htmlFor={"cost " + id} className="input-label">
            Unit Cost:
          </label>
          <div className="input-decoration cost-decoration">$</div>
          <input
            className="mat-input mat-number"
            name="cost"
            id={"cost " + id}
            type="tel"
            placeholder="Cost"
            value={currentCost}
            min="0.00"
            step="0.01"
            onKeyDown={handleCostKeyDown}
            onChange={(e) => updateMaterial(e, id, "cost")}
          />
        </div>
      </td>

      <td className="td-total">
        <div className="input-wrapper total-wrapper">
          <label className="input-label total-label">Total:</label>
          <div className="total-inner-wrapper">
            <div className="input-decoration">$</div>
            <div className="mat-input mat-total">{total}</div>
          </div>
        </div>
      </td>

      <td>
        <FontAwesomeIcon
          icon="svg-inline--fa fa-trash"
          onClick={() => {
            setSelectedMaterial({ description, id });
            handleModalOpening("remove-material");
          }}
        />
      </td>
    </tr>
  );
};

export default MaterialItem;
