import { useState } from "react";

import asyncAPICall from "../../util/apiWrapper";
import { errorToast, successfulToast } from "../../util/toastNotifications";
import { useAppData } from "../context/AppDataContext";

const DeletePARForm = (props) => {
  const { setIsModalOpen, selectedPAR, setSubmittedPARs, filteredPARs } = props;
  const { getParReports } = useAppData();
  const [isLoading, setIsLoading] = useState(false);

  const handleDeactivatePAR = async () => {
    setIsLoading(true);

    if (filteredPARs) {
      setSubmittedPARs(filteredPARs);
    }

    const fetchedReports = new Promise((resolve, reject) => {
      asyncAPICall(
        `api:W2oHkD04/partblmain/deactivatePAR?dcrNumber=${selectedPAR.dcrNumber}`,
        "POST",
        null,
        (res) => {
          if (res.ok) {
            successfulToast(
              "Successfuly deleted " +
                selectedPAR.jobNumber +
                " on " +
                selectedPAR.date
            );

            resolve(true);
          }
        },
        null,
        (err) => {
          errorToast(
            "Something went wrong with deleting " +
              selectedPAR.jobNumber +
              " on " +
              selectedPAR.date
          );

          reject(console.error(err));
        }
      );
    });

    fetchedReports
      .then(() => {
        getParReports();
      })
      .catch((err) => {
        console.error("Deactivating PAR error: ", err);
      })
      .finally(() => {
        setIsModalOpen(false);
      });
  };

  return (
    <div className="all-employee-phase-confirm-modal-wrapper">
      <h3 className="remove-header">
        {"Are you sure you want to delete " +
          selectedPAR.jobNumber +
          " on " +
          selectedPAR.date}
      </h3>
      <button
        className="modal-button"
        onClick={handleDeactivatePAR}
        disabled={isLoading}
      >
        YES
      </button>
      <button
        className="modal-button"
        onClick={() => setIsModalOpen(false)}
        disabled={isLoading}
      >
        NO
      </button>
    </div>
  );
};

export default DeletePARForm;
