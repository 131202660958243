const ResetParForm = (props) => {
  const { setIsModalOpen, resetPar, setResetting } = props;

  return (
    <div className="remove-item-modal-container">
      <h3 className="remove-header">
        Are you sure you want to clear your PAR?
      </h3>

      <button
        className="modal-button"
        onClick={() => {
          setResetting(true);
          resetPar("modal");
        }}
      >
        YES
      </button>

      <button className="modal-button" onClick={() => setIsModalOpen(false)}>
        NO
      </button>
    </div>
  );
};

export default ResetParForm;
