import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./styles/app.scss";
import LoginPage from "./pages/loginPage";
import LoginContainer from "./loginContainer";
import DefaultContainer from "./defaultContainer";
import solidIcons from "./util/fontawesome/solidIcons";

solidIcons();

function App() {
  const [sessionToken, setSessionToken] = useState(null);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/login"
            render={(props) => {
              return (
                <LoginPage
                  {...props}
                  sessionToken={sessionToken}
                  setSessionToken={setSessionToken}
                />
              );
            }}
          />
          <Route
            exact
            path="/login/email/sent"
            render={(props) => {
              return (
                <LoginContainer
                  {...props}
                  sessionToken={sessionToken}
                  setSessionToken={setSessionToken}
                />
              );
            }}
          />
          <Route
            exact
            path="/login/password/recovery"
            render={(props) => {
              return (
                <LoginContainer
                  {...props}
                  sessionToken={sessionToken}
                  setSessionToken={setSessionToken}
                />
              );
            }}
          />
          <Route
            exact
            path="/login/password/temporary"
            render={(props) => {
              return (
                <LoginContainer
                  {...props}
                  sessionToken={sessionToken}
                  setSessionToken={setSessionToken}
                />
              );
            }}
          />
          <Route
            exact
            path="/login/password/change"
            render={(props) => {
              return (
                <LoginContainer
                  {...props}
                  sessionToken={sessionToken}
                  setSessionToken={setSessionToken}
                />
              );
            }}
          />
          <Route
            exact
            path="/login/auth"
            render={(props) => {
              return (
                <LoginContainer
                  {...props}
                  sessionToken={sessionToken}
                  setSessionToken={setSessionToken}
                />
              );
            }}
          />
          <Redirect exact from="/" to="/login" />
          <Route
            render={(props) => (
              <DefaultContainer
                {...props}
                sessionToken={sessionToken}
                setSessionToken={setSessionToken}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
