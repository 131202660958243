import { useEffect, useState } from "react";
import Modal from "react-modal";
import Cookies from "js-cookie";

import Alert from "./components/alert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

Modal.setAppElement("#root");

const Home = (props) => {
  const [alertTitle, setAlertTitle] = useState("");
  const [alertSubTitle, setAlertSubTitle] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [leftColumnHeader, setLeftColumnHeader] = useState("");
  const [leftColumnData, setLeftColumnData] = useState("");
  const [rightColumnHeader, setRightColumnHeader] = useState("");
  const [rightColumnData, setRightColumnData] = useState("");

  useEffect(() => {
    let session_token = Cookies.get("session_token");

    if (!session_token) {
      props.history.push("/login");
    }
  }, [props.history]);

  const handleAlert = (type) => {
    switch (type) {
      case "exhaustion":
        setAlertTitle("Exhaustion Alert");
        setAlertSubTitle(
          "The below employees have recorded shifts over 12 hours"
        );
        setLeftColumnHeader("Employee Name");
        setLeftColumnData("Jose Duarte Garcia");
        setRightColumnHeader("Todays Hours");
        setRightColumnData("13.5 hours");
        break;
      case "incident":
        setAlertTitle("Incident Alert");
        setAlertSubTitle(
          "The below employees have recorded over 40 hours this week"
        );
        setLeftColumnHeader("Employee Name");
        setLeftColumnData("Boyd Chatwin");
        setRightColumnHeader("Incident");
        setRightColumnData("Sprained Ankle");
        break;
      case "overtime":
        setAlertTitle("Overtime Alert");
        setAlertSubTitle("Onsite delays in the construction process");
        setLeftColumnHeader("Employee Name");
        setLeftColumnData("Michael Duncan");
        setRightColumnHeader("Hours");
        setRightColumnData("60 hours");
        break;
      case "delays":
        setAlertTitle("Delay Alert");
        setAlertSubTitle("Onsite incidents that have been submitted");
        setLeftColumnHeader("Job");
        setLeftColumnData("Macey’s Parking Lot");
        setRightColumnHeader("Delay Type");
        setRightColumnData("Inclement Weather");
        break;
      default:
        return type;
    }
    setModalIsOpen(true);
  };

  return (
    <div className="home-wrapper">
      <h6 onClick={() => handleAlert("exhaustion")}>Exhaustion</h6>
      <h6 onClick={() => handleAlert("incident")}>Incident</h6>
      <h6 onClick={() => handleAlert("delays")}>Delays</h6>
      <h6 onClick={() => handleAlert("overtime")}>Overtime</h6>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={{
          overlay: {},
          content: {
            width: "45%",
            height: "40%",
            position: "relative",
            top: "30%",
            left: "25%",
            backgroundColor: "#eae9e9",
          },
        }}
      >
        <FontAwesomeIcon
          icon="fas fa-times close-modal"
          onClick={() => setModalIsOpen(false)}
        />

        <Alert
          alertTitle={alertTitle}
          alertSubTitle={alertSubTitle}
          leftColumnHeader={leftColumnHeader}
          leftColumnData={leftColumnData}
          rightColumnHeader={rightColumnHeader}
          rightColumnData={rightColumnData}
        />
      </Modal>
    </div>
  );
};

export default Home;
