const VerifyForm = (props) => {
  const { message, handleConfirm, handleCancel } = props;

  return (
    <div className="verify-modal-container">
      <div className="verify-modal-message">{message}</div>
      <div className="verify-options-wrapper">
        <button className="go-back submit-button" onClick={handleCancel}>
          No
        </button>
        <button onClick={handleConfirm} className="confirm submit-button">
          Yes
        </button>
      </div>
    </div>
  );
};
export default VerifyForm;
