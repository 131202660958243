import { useEffect, useState, useContext, useCallback } from "react";

import useDebounce from "../hooks/useDebounce";
import useAbortEffect from "../hooks/useAbortEffect";

import EquipmentItem from "../EquipmentItem";
import asyncAPICall from "../../util/apiWrapper";
import { ParDataContext } from "../../defaultContainer";
import Modal from "../modals/Modal";
import AllPhaseConfirmForm from "./AllPhaseConfirmForm";
import LoadingSpinner from "../helpers/LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EquipmentForm = (props) => {
  const {
    phaseNum,
    setIsModalOpen,
    allPhaseEquipment,
    setAllPhaseEquipment,
    prevAllPhaseEquipment,
  } = props;

  const [allEquipment, setAllEquipment] = useState([]);
  const [filteredEquipment, setFilteredEquipment] = useState([]);
  const [searchResult, setSearchResult] = useState("");
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const { parFormDataState, parDispatch } = useContext(ParDataContext);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [toRemove, setToRemove] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [equipNums, setEquipNums] = useState([]);
  const [currentlySelected, setCurrentlySelected] = useState([]);
  const debounceValue = useDebounce(searchResult);

  const submit = () => {
    const removal = [];
    prevAllPhaseEquipment?.forEach((equipment) => {
      if (!allPhaseEquipment.includes(equipment)) {
        removal.push(equipment.equipNum);
      }
    });
    setToRemove(removal);

    if (phaseNum) {
      parDispatch({
        type: "addRecord",
        payload: {
          phaseCode: phaseNum,
          newRecords: selectedEquipment,
          attributeName: "equipmentData",
        },
      });
    } else {
      parDispatch({
        type: "addRecords",
        attributeName: "equipmentData",
        payload: allPhaseEquipment,
      });
    }

    setIsModalOpen(false);
  };

  const addRemoveEquipment = useCallback(
    (e) => {
      e.preventDefault();

      const selected = e.target.selected;
      const currentEquipment = e.target.id;
      const currentDescription = filteredEquipment.filter(
        (fE) => fE.Equipment === currentEquipment
      )[0].Description;

      if (!selected) {
        const equipmentData = {
          equipNum: currentEquipment,
          description:
            currentDescription.length > 30
              ? `${currentDescription.slice(0, 30)}...`
              : currentDescription,
          operatedHours: props.hoursWorked,
          equipNote: "",
        };

        setSelectedEquipment((sE) => [...sE, equipmentData]);
        if (!phaseNum) {
          setAllPhaseEquipment((aPE) => [...aPE, equipmentData]);
        }
      } else {
        const removeEquipment = selectedEquipment.filter((equip) => {
          return currentEquipment !== equip.equipNum;
        });

        setSelectedEquipment(removeEquipment);

        if (!phaseNum) {
          const removeFromAllEquipment = allPhaseEquipment.filter(
            (equipment) => {
              return equipment.equipNum !== currentEquipment;
            }
          );

          setAllPhaseEquipment(removeFromAllEquipment);
        }
      }
    },
    [
      filteredEquipment,
      selectedEquipment,
      allPhaseEquipment,
      phaseNum,
      props.hoursWorked,
      setAllPhaseEquipment,
    ]
  );

  useEffect(() => {
    if (!phaseNum && selectedEquipment.length === 0) {
      setSelectedEquipment(allPhaseEquipment);
    }
  }, [allPhaseEquipment, phaseNum, selectedEquipment.length]);

  const renderData = useCallback(() => {
    const phaseObjects = filteredEquipment.map((p) => {
      const selectedItem = selectedEquipment.filter((sE) => {
        return sE.equipNum === p.Equipment;
      });

      if (!equipNums.includes(p.Equipment)) {
        return (
          <EquipmentItem
            key={p.id}
            equipmentData={p}
            selectedEquipment={selectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
            hoursWorked={props.hoursWorked}
            allPhaseEquipment={allPhaseEquipment}
            setAllPhaseEquipment={setAllPhaseEquipment}
            phaseNum={phaseNum}
            isSelected={selectedItem.length > 0 ? true : false}
            currentlySelected={currentlySelected}
            setCurrentlySelected={setCurrentlySelected}
            addRemoveEquipment={addRemoveEquipment}
          />
        );
      } else {
        return null;
      }
    });

    return phaseObjects;
  }, [
    phaseNum,
    addRemoveEquipment,
    allPhaseEquipment,
    currentlySelected,
    equipNums,
    filteredEquipment,
    props.hoursWorked,
    selectedEquipment,
    setAllPhaseEquipment,
  ]);

  useEffect(() => {
    if (toRemove.length > 0) {
      parDispatch({
        type: "removeFromAllPhases",
        attributeName: "equipmentData",
        payload: toRemove,
      });
    }
  }, [toRemove, parDispatch]);

  useAbortEffect((signal) => {
    function getEquipment() {
      asyncAPICall(
        "api:KE7MCNYf/vequipmentnumsonlyAll",
        "GET",
        null,
        null,
        (data) => {
          if (data) {
            setAllEquipment(data.resource);
            setIsLoading(false);
          }
        },
        (err) => {
          if (!signal.aborted) {
            console.log("Getting equipment Error", err);
          }
        },
        signal
      );
    }
    getEquipment();
  }, []);

  useEffect(() => {
    setFilteredEquipment(
      allEquipment.filter(
        (equipmentObject) =>
          equipmentObject.Description.toLowerCase().includes(
            debounceValue.toLowerCase()
          ) ||
          equipmentObject.Equipment.toLowerCase().includes(
            debounceValue.toLowerCase()
          )
      )
    );
  }, [debounceValue, allEquipment]);

  useEffect(() => {
    const equipmentNumbers = [];

    if (phaseNum) {
      parFormDataState.phaseCodes[phaseNum].equipmentData.forEach(
        (equipment) => {
          equipmentNumbers.push(equipment.equipNum);
        }
      );
    }

    setEquipNums((eN) => [...eN, ...equipmentNumbers]);
  }, [parFormDataState.phaseCodes, phaseNum]);

  return (
    <div className="main-equipment-container">
      <div className="equipment-container">
        <h1>{phaseNum ? `Phase Code ${phaseNum}` : "Add to All Phases"}</h1>

        <div className="input-header-wrapper">
          <h2>Equipment Data</h2>

          <FontAwesomeIcon className="search-icon" icon="fa fa-search" />

          <input
            value={searchResult}
            type="search"
            onChange={(e) => setSearchResult(e.target.value)}
          />
        </div>

        <hr />

        <div
          className={
            isLoading
              ? "equipment-data-wrapper mobile"
              : "equipment-data-wrapper"
          }
        >
          {isLoading ? <LoadingSpinner /> : renderData()}
        </div>

        <div className="separator-center" />
        <div className="separator-right" />

        <div className="pagination-save-wrapper">
          <div className="pagination-wrapper">
            {["B-", "BT-", "BE-", "RE-", "TRE-", "TB-"].map((item) => (
              <div key={item} onClick={() => setSearchResult(item)}>
                {item}
              </div>
            ))}
          </div>

          <div className="save-btn-wrapper">
            <button
              onClick={phaseNum ? submit : () => setConfirmModalOpen(true)}
            >
              Save
            </button>
          </div>

          <Modal
            isModalOpen={confirmModalOpen}
            onRequestClose={() => setConfirmModalOpen(false)}
            modalDescription="all-phase-confirm"
            modalParts={{
              "all-phase-confirm": {
                modalContent: "verify-modal-styles",
                modalForm: (
                  <AllPhaseConfirmForm
                    setIsModalOpen={setConfirmModalOpen}
                    submit={submit}
                    parentSetShowModal={setIsModalOpen}
                  />
                ),
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EquipmentForm;
