import { useState, useContext, useEffect } from "react";

import PastPhases from "../PastPhases";
import { ParDataContext } from "../../defaultContainer";
import asyncAPICall from "../../util/apiWrapper";
import LoadingSpinner from "../helpers/LoadingSpinner";
import useAbortEffect from "../hooks/useAbortEffect";

export default function AutoFill({ setIsModalOpen, autofillData }) {
  const [phaseCodes, setPhaseCodes] = useState([]);
  const [times, setTimes] = useState({});
  const [sortedAutofillData, setSortedAutofillData] = useState([]);
  const [submitterData, setSubmitterData] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { parDispatch } = useContext(ParDataContext);

  const setPhases = () => {
    setIsModalOpen(false);
    parDispatch({
      type: "updateInputField",
      payload: {
        field: "startTime",
        value: times.startTime,
      },
    });
    parDispatch({
      type: "updateInputField",
      payload: {
        field: "stopTime",
        value: times.stopTime,
      },
    });
    parDispatch({
      type: "autofillPhases",
      autofillPhases: phaseCodes,
    });
  };

  const handlePhases = () => {
    setShowDropdown((prev) => !prev);
    setIsLoading(true);
    setSubmitterData([]);
  };

  const renderAutofillPhases = () => {
    return Object.keys(phaseCodes).map((phase) => {
      return (
        <div className="autofill-phase" key={phase}>
          {phaseCodes[phase].phaseDescription.slice(0, 100)}
        </div>
      );
    });
  };

  const renderDatePhases = () => {
    return submitterData.map((submitterObj) => {
      if (Object.keys(submitterObj.phases).length > 0) {
        return (
          <PastPhases
            setPhaseCodes={setPhaseCodes}
            setShowDropdown={setShowDropdown}
            phaseObj={submitterObj}
            key={submitterObj.id}
            setTimes={setTimes}
          />
        );
      } else {
        return null;
      }
    });
  };

  useAbortEffect(
    (signal) => {
      if (showDropdown) {
        const allPhasePromises = [];

        sortedAutofillData.forEach((phaseObj) => {
          const phasePromise = new Promise((resolve, reject) => {
            asyncAPICall(
              `api:KE7MCNYf/vEmployeesNoSpacesIdByID?employeeID=${phaseObj.submitterNumber}`,
              "GET",
              null,
              null,
              (data) => {
                const submitterName = `${data[0].FirstName} ${data[0]?.MidName} ${data[0].LastName}`;
                const submitterObj = {
                  id: phaseObj.id,
                  submitter: submitterName,
                  phaseDate: phaseObj.phaseDate,
                  phases: phaseObj.phases,
                  startTime: phaseObj.startTime,
                  stopTime: phaseObj.stopTime,
                };

                setSubmitterData((prev) => [...prev, submitterObj]);
                resolve(true);
              },
              (err) => {
                setIsLoading(false);
                reject("Errors: getting autofill submitter name", err);
              },
              signal
            );
          });

          allPhasePromises.push(phasePromise);
        });

        Promise.all(allPhasePromises)
          .catch(console.error)
          .finally(() => setIsLoading(false));
      }
    },
    [showDropdown]
  );

  useEffect(() => {
    const dateCopy = [...autofillData];

    const sortedDates = dateCopy.sort(function compareDates(dateOne, dateTwo) {
      const dateA = new Date(dateOne.phaseDate);
      const dateB = new Date(dateTwo.phaseDate);

      return dateA - dateB;
    });

    sortedDates.reverse().slice(0, 7);

    setSortedAutofillData(sortedDates);
  }, [autofillData]);

  return (
    <div className="autofill-wrapper">
      <div className="header-wrapper">
        <div className="autofill">Autofill</div>

        <button className="date-phase-button" onClick={handlePhases}>
          Past Phase Codes By Date
        </button>

        {showDropdown && (
          <div className="dropdown-container">
            {isLoading ? <LoadingSpinner /> : renderDatePhases()}
          </div>
        )}
      </div>

      <div className="hr" />

      <div className="body-wrapper">
        <div className="phase-codes">Phase Codes</div>

        {Object.keys(phaseCodes).length === 0 && (
          <div className="date-select">
            Select a date to autofill the phase codes
          </div>
        )}

        {Object.keys(phaseCodes).length > 0 && (
          <div className="prompt-wrapper">
            {renderAutofillPhases()}

            <div className="prompt">
              Would you like to autofill todays PAR with the above <br />
              information: phase codes, labor data and <br />
              equipment data?
            </div>

            <div className="buttons-wrapper">
              <button onClick={setPhases}>Yes</button>
              <button onClick={() => setIsModalOpen(false)}>No</button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
