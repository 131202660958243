import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import useAbortEffect from "../components/hooks/useAbortEffect";
import asyncAPICall from "../util/apiWrapper";
import ParNotificationItem from "../components/ParNotificationItem";
import { ErrorMessage } from "../util/ErrorMessage";
import Modal from "../components/modals/Modal";
import DeletePARForm from "../components/forms/DeletePARForm";
import { useAppData } from "../components/context/AppDataContext";
import useDebounce from "../components/hooks/useDebounce";

const ApproveSubmissions = (props) => {
  const [isErrorMessage, setIsErrorMessage] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPAR, setSelectedPAR] = useState(false);
  const { submittedPARs, setSubmittedPARs, getSubmittedReports } = useAppData();
  const [filteredPARs, setFilteredPARs] = useState([]);
  const [searchedPAR, setSearchedPAR] = useState([]);
  const [searchedJob, setSearchedJob] = useState("");
  const [searchedDate, setSearchedDate] = useState("");

  const debounceJob = useDebounce(searchedJob);
  const today = new Date().toISOString().split("T")[0];

  const handleDeletePendingPAR = (par) => {
    setSelectedPAR(par);
    setIsModalOpen(true);
    setFilteredPARs(submittedPARs.filter((item) => item.id !== par.id));
  };

  useAbortEffect(
    (signal) => {
      if (searchedDate && debounceJob?.length > 0) {
        asyncAPICall(
          `api:W2oHkD04/partblmainByJobAndDate?job=${debounceJob}&date=${searchedDate}`,
          "GET",
          null,
          null,
          (data) => {
            setSearchedPAR(data);
          },
          (err) => {
            console.log("error searching", err);
          },
          signal
        );
      }
    },
    [debounceJob, searchedDate]
  );

  useEffect(() => {
    getSubmittedReports();
  }, [getSubmittedReports]);

  useEffect(() => {
    const pathName = props.history.location.pathname;
    const pathNameArray = pathName.split("+");
    if (pathNameArray?.length > 2) {
      setSearchedDate(pathNameArray[1]);
      setSearchedJob(pathNameArray[2]);
    }
  }, [props.history.location.pathname]);

  return (
    <div className="submissions-wrapper">
      <div className="header-wrapper">
        <h1 className="approve-submissions-header">APPROVE SUBMISSIONS</h1>

        <a
          className="instructions-link"
          href="https://scribehow.com/shared/PAR_Approver__CH8IlpZCQvKX5RVHV2_5yA "
          target="_blank"
          rel="noreferrer"
        >
          Approver Instructions
        </a>
      </div>

      <div className="approvals-wrapper">
        <div className="assigned-approvals-container">
          <h1 className="assigned-approvals-header">ASSIGNED APPROVALS</h1>

          <div className="par-forms-container">
            {submittedPARs
              .map((par) => {
                return (
                  <ParNotificationItem
                    key={par.id}
                    formData={par}
                    handleDeletePendingPAR={handleDeletePendingPAR}
                  />
                );
              })
              .sort(
                (a, b) =>
                  a.props.formData.created_at - b.props.formData.created_at
              )}
          </div>
        </div>

        <div className="approvals-filter-container">
          <div className="search-inputs-wrapper">
            <div className="date-container inputs">
              <label htmlFor="dcrNumber">Date</label>
              <input
                id="dcrNumber"
                className="date-input"
                type="date"
                max={today}
                value={searchedDate}
                onChange={(e) => {
                  setSearchedDate(e.target.value);
                }}
                maxLength={45}
              />
            </div>
            <div className="job-number-container inputs">
              <label htmlFor="dcrNumber">Job Number</label>
              <input
                id="dcrNumber"
                type="search"
                placeholder="Search Job"
                value={searchedJob}
                onChange={(e) => {
                  setSearchedJob(e.target.value);
                }}
                maxLength={12}
              />
            </div>
          </div>
          <div className="search-results-container">
            <h2>Search Results</h2>
            <div className="filter-search-wrapper">
              {searchedPAR.length === 0 ? (
                <h2>No PARs</h2>
              ) : (
                searchedPAR.map((par) => {
                  return (
                    <div
                      key={par.dcrNumber}
                      className="searched-report-wrapper"
                    >
                      <h3 className="searched-job-number">
                        <FontAwesomeIcon icon="fas fa-check-circle" />
                        {par.jobNumber}
                      </h3>
                      <h3 className="searched-date">
                        {par.submitterName[0].name}
                      </h3>

                      <Link
                        className="searched-report-link"
                        to={{
                          pathname: `/par-verification/searched+${par.dcrNumber}`,
                        }}
                      >
                        View Report
                      </Link>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>

      {isErrorMessage && (
        <ErrorMessage
          errorMessage="Sorry, we could not retrieve the PAR's ready for approval."
          setIsErrorMessage={setIsErrorMessage}
        />
      )}

      <Modal
        isModalOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        modalDescription="delete-par"
        modalParts={{
          "delete-par": {
            modalContent: "verify-modal-styles",
            modalForm: (
              <DeletePARForm
                selectedPAR={selectedPAR}
                setIsModalOpen={setIsModalOpen}
                handleDeletePendingPAR={handleDeletePendingPAR}
                setSubmittedPARs={setSubmittedPARs}
                filteredPARs={filteredPARs}
              />
            ),
          },
        }}
      />
    </div>
  );
};

export default ApproveSubmissions;
