import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const ParNotificationItem = (props) => {
  const { formData, handleDeletePendingPAR } = props;

  return (
    <div className="par-notification-container">
      <FontAwesomeIcon icon="fa fa-check-circle" />

      <h3>{formData.date}</h3>
      <h4>{formData.jobNumber}</h4>
      <h4 className="submitter-name">{formData.submitterName[0].name}</h4>

      <Link
        className="approve-header"
        to={{
          pathname: `/par-verification/confirm+${formData.dcrNumber}`,
        }}
      >
        Approve?
      </Link>

      <FontAwesomeIcon
        onClick={() => handleDeletePendingPAR(formData)}
        icon="fa fa-trash"
      />
    </div>
  );
};

export default ParNotificationItem;
