const Alert = (props) => {
  return (
    <div className="alert-container">
      <div className="alert">
        <div className="alert-header">
          <h1>{props.alertTitle}</h1>
          <h2>{props.alertSubTitle}</h2>
        </div>

        <div className="alert-columns">
          <div className="column-1">
            <h2>{props.leftColumnHeader}</h2>
            <h3>{props.leftColumnData}</h3>
          </div>

          <div className="column-2">
            <h2>{props.rightColumnHeader}</h2>
            <h3>{props.rightColumnData}</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Alert;
