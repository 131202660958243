const AllPhaseConfirmForm = (props) => {
  const { setIsModalOpen, submit, parentSetShowModal, content } = props;

  return (
    <div className="all-employee-phase-confirm-modal-wrapper">
      <h3 className="remove-header">
        Add {content} to <br />
        <span>ALL</span>
        <br /> phases in PAR?
      </h3>
      <button
        className="modal-button"
        onClick={() => {
          submit();
          setIsModalOpen(false);
          parentSetShowModal(false);
        }}
      >
        YES
      </button>
      <button className="modal-button" onClick={() => setIsModalOpen(false)}>
        NO
      </button>
    </div>
  );
};

export default AllPhaseConfirmForm;
