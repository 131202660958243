import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import preventNumScroll from "./helpers/preventNumScroll";

const SubcontractorItem = (props) => {
  const {
    setSelectedSubcontractor,
    handleModalOpening,
    name,
    description,
    dailyHours,
    employeeCount,
    id,
    type,
    updateSubcontractor,
    approvedSubcontractors,
  } = props;

  const renderOptions = () => {
    return approvedSubcontractors.map((subcontractor) => {
      return (
        <option value={subcontractor.name} key={subcontractor.id}>
          {subcontractor.name}
        </option>
      );
    });
  };

  const handleSubcontractor = (e) => {
    const chosenSubcontractor = approvedSubcontractors.filter(
      (aS) => aS.name === e.target.value
    )[0];

    updateSubcontractor(
      {
        target: {
          value:
            chosenSubcontractor !== undefined ? chosenSubcontractor.name : "",
        },
      },
      id,
      "name"
    );
    updateSubcontractor(
      {
        target: {
          value:
            chosenSubcontractor !== undefined ? chosenSubcontractor.vendor : "",
        },
      },
      id,
      "vendor"
    );
  };

  return (
    <tr className="table-row">
      <td>
        <div>{type === "bhi" ? "BHI: " : "Other:"}</div>
      </td>

      {type === "supplemental" || approvedSubcontractors.length === 0 ? (
        <td className="padding-20">
          <input
            type="text"
            placeholder="Subcontractor Name"
            value={name}
            onChange={(e) => updateSubcontractor(e, id, "name")}
            maxLength={60}
          />
        </td>
      ) : (
        <td>
          <select
            className="select-approved-subs"
            value={name}
            onChange={(e) => {
              handleSubcontractor(e);
            }}
          >
            <option value="">Select </option>

            {renderOptions()}
          </select>
        </td>
      )}

      <td>
        <input
          className="input-sub-details"
          type="text"
          placeholder="Subcontractor Description"
          value={description}
          onChange={(e) => updateSubcontractor(e, id, "description")}
          maxLength={80}
        />
      </td>

      <td>
        <input
          className="input-number"
          type="number"
          placeholder="Shift Duration (In Hours)"
          value={dailyHours}
          min="0"
          onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
          onChange={(e) => updateSubcontractor(e, id, "dailyHours")}
          onWheel={preventNumScroll}
        />
      </td>

      <td>
        <input
          className="input-number"
          type="number"
          placeholder="Employee Count"
          value={employeeCount}
          min="0"
          onKeyDown={(e) => e.code === "Minus" && e.preventDefault()}
          onChange={(e) => updateSubcontractor(e, id, "employeeCount")}
          onWheel={preventNumScroll}
        />
      </td>

      <td>
        <FontAwesomeIcon
          icon="svg-inline--fa fa-trash"
          onClick={() => {
            setSelectedSubcontractor({ name, id });
            handleModalOpening("remove-employee");
          }}
        />
      </td>
    </tr>
  );
};

export default SubcontractorItem;
