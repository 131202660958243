import { useContext, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ParDataContext } from "../../defaultContainer";
import { hoursWorkedIsValid } from "./hoursWorkedIsValid";
import preventNumScroll from "./preventNumScroll";
import getDescriptionFromClass from "./getDescriptionFromClass";

export default function PrevailingWagesTable(props) {
  const {
    parFormDataState,
    phaseNum,
    handleModalOpening,
    setEmployeeId,
    setEmployeeClasses,
    setJobClass,
    hoursUpdated,
    setHoursUpdated,
    pwClassesByJob,
    editInfo,
    clearTooManyHours,
    handleModal,
    updateHoursWorked,
    setSumAfterDelete,
  } = props;
  const { parDispatch } = useContext(ParDataContext);
  const formData = parFormDataState;
  const laborData = formData.phaseCodes[phaseNum]?.laborData;

  function dispatchHours(value, keyName, employeeId) {
    if (isNaN(parseInt(value)) === false) {
      parDispatch({
        type: "updatePhaseCodeFieldObject",
        payload: {
          phaseCode: props.phaseNum,
          attributeName: "laborData",
          fieldName: "classes",
          updatedValue: value,
          rowId: employeeId,
          filterName: "id",
          objectKey: keyName,
        },
      });
      setHoursUpdated("y");
    }
  }

  function dispatchLaborNotes(value, employeeId) {
    parDispatch({
      type: "updatePhaseCodeField",
      payload: {
        phaseCode: props.phaseNum,
        attributeName: "laborData",
        fieldName: "laborNotes",
        updatedValue: value,
        rowId: employeeId,
        filterName: "id",
      },
    });
  }

  function handleDeleteClassButtonClick(classes, jobClass, employeeId) {
    setEmployeeClasses(classes);
    setJobClass(jobClass);
    setEmployeeId(employeeId);
    handleModalOpening("delete-class");
  }

  function handleButtonClick(id) {
    setEmployeeId(id);
    handleModalOpening("add-class");
  }

  function renderWagesTable() {
    return laborData?.map((person) => {
      return (
        <div key={person.id} className="wages-table">
          <div className="employee-data">
            <div className="employee">
              <p className="employee-name">{person.name}</p>
              <p className="employee-id">{`(${person.id})`}</p>
            </div>
            {editInfo ? (
              <input
                type="text"
                className="employee-note"
                placeholder="Labor Notes"
                defaultValue={person.laborNotes}
                onBlur={(e) => dispatchLaborNotes(e.target.value, person.id)}
              />
            ) : (
              <div className="employee-note">{person.laborNotes}</div>
            )}
            <p className="employee-hours">Total Hours | {person.hoursWorked}</p>
            {editInfo && (
              <>
                <button
                  className="add-work"
                  onClick={() => handleButtonClick(person.id)}
                >
                  Add Work
                </button>
                <button
                  className="remove-employee"
                  onClick={() => {
                    setEmployeeId(person.id);
                    handleModal("laborData", person.name, "name", "employee");
                  }}
                >
                  <FontAwesomeIcon icon="fas fa-trash" />
                </button>
              </>
            )}
          </div>
          {renderClasses(
            person.classes,
            person.id,
            person.name,
            person.hoursWorked
          )}
        </div>
      );
    });
  }

  function renderClasses(classes, employeeId, employeeName, sum) {
    if (classes) {
      const values = Object.entries(classes);
      return values?.map((jobClass) => {
        return (
          <div className="labor-data" key={jobClass[0]}>
            <p className="class-header">Class |</p>
            <p className="class-name">
              {getDescriptionFromClass(jobClass[0], pwClassesByJob)
                ? getDescriptionFromClass(jobClass[0], pwClassesByJob)
                    .Description
                : jobClass[0]}
            </p>
            {editInfo ? (
              <>
                <input
                  className="class-hours"
                  defaultValue={jobClass[1]}
                  type="number"
                  min="0"
                  max="24"
                  step={0.25}
                  onWheel={preventNumScroll}
                  onBlur={(e) => {
                    (hoursWorkedIsValid(formData, employeeId, "laborData") ===
                      "exceeds" &&
                      (e.target.value = "0") &&
                      clearTooManyHours(
                        "laborData",
                        "classes",
                        employeeId,
                        "id",
                        `${employeeName} Hours Has Exceeded Total Hours From PAR Job Information Section.`,
                        true,
                        jobClass[0]
                      )) ||
                      (hoursWorkedIsValid(formData, employeeId, "laborData") ===
                        "precision" &&
                        (e.target.value = "0") &&
                        clearTooManyHours(
                          "laborData",
                          "classes",
                          employeeId,
                          "id",
                          "Cannot Input More Than Two Decimal Places.",
                          true,
                          jobClass[0]
                        ));
                  }}
                  onChange={(e) =>
                    dispatchHours(e.target.value, jobClass[0], employeeId)
                  }
                />
                <button
                  className="remove-work"
                  onClick={() => {
                    handleDeleteClassButtonClick(classes, jobClass, employeeId);
                    setSumAfterDelete(sum - jobClass[1]);
                  }}
                >
                  <FontAwesomeIcon icon="fas fa-trash" />
                </button>
              </>
            ) : (
              <div className="class-hours-view">{jobClass[1]}</div>
            )}
          </div>
        );
      });
    }
  }

  useEffect(() => {
    laborData?.forEach((person) => {
      let sum = 0;
      for (const indivClass in person.classes) {
        sum += parseFloat(person.classes[indivClass]);
        if (hoursUpdated === "y") {
          updateHoursWorked(sum, person.id);
        }
      }
    });
  }, [laborData, hoursUpdated, updateHoursWorked]);

  return (
    laborData?.length !== 0 && (
      <div className="wages-container">
        <div className="wages-header">
          <p className="header-employee">Employee</p>
          <p className="header-note">Labor Note</p>
          <p className="header-hours">Hours Worked</p>
        </div>
        {renderWagesTable()}
      </div>
    )
  );
}
