import { useState, useContext } from "react";

import { ParDataContext } from "../defaultContainer";
import { ParInput } from "./helpers/ParFormComponents";
import asyncAPICall from "../util/apiWrapper";
import useAbortEffect from "./hooks/useAbortEffect";

const TimeMaterials = (props) => {
  const { resetting, basicFieldUpdate, jobNumber } = props;
  const [showTM, setShowTM] = useState(true);
  const { parFormDataState } = useContext(ParDataContext);

  useAbortEffect(
    (signal) => {
      if (jobNumber) {
        asyncAPICall(
          `api:W2oHkD04/vcustomersbyJob?job=${jobNumber}`,
          "GET",
          null,
          null,
          (data) => {
            if (data) {
              basicFieldUpdate(
                `${data[0].name} ${data[0].customer}`,
                "matCustomer",
                "updateInputField"
              );
            }
          },
          (err) => {
            if (!signal.aborted) {
              console.error("Getting Time and Material Customer Error", err);
            }
          },
          signal
        );
      }
    },
    [jobNumber]
  );

  return (
    <div className="time-materials-container">
      <div className="heading-wrapper tm">
        <button
          className="section-label"
          onClick={() => setShowTM((prev) => !prev)}
        >
          Time & Material
        </button>
      </div>
      {showTM && (
        <div className="time-materials-content-container">
          <div className="job-information-wrapper">
            <ParInput
              type="text"
              label="Customer"
              customClass="customer-container"
              value={parFormDataState.matCustomer}
              readOnly={true}
              disabled={resetting}
            />

            <ParInput
              type="text"
              label="Customer Code"
              customClass="customer-code-container"
              placeholder="Enter Customer Code"
              value={parFormDataState.matCustomerCode}
              onChange={(e) =>
                basicFieldUpdate(
                  e.target.value,
                  "matCustomerCode",
                  "updateInputField"
                )
              }
              disabled={resetting}
            />
          </div>

          <div className="job-information-wrapper">
            <ParInput
              type="text"
              label="Onsite Representative"
              placeholder="Enter Onsite Representative"
              customClass="representative-container"
              value={parFormDataState.matOsr}
              onChange={(e) =>
                basicFieldUpdate(e.target.value, "matOsr", "updateInputField")
              }
              disabled={resetting}
            />

            <ParInput
              type="text"
              label="Jobsite"
              customClass="jobsite-container"
              placeholder="Enter Jobsite"
              value={parFormDataState.matJobsite}
              onChange={(e) =>
                basicFieldUpdate(
                  e.target.value,
                  "matJobsite",
                  "updateInputField"
                )
              }
              disabled={resetting}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeMaterials;
